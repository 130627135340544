
<h1 mat-dialog-title *transloco="let t" >{{ t('contact_selector-customer_contact') }}</h1>
<div mat-dialog-content>
    <mat-radio-group class="contact-radio-group" [formControl]="contactTypeControl">
      <div class="tile-radio-buttons">
        <!-- Control hide since is not ready to be used -->
        <!--<mat-radio-button class="contact-radio-button" value="currentUserContact" *ngIf="data.currentUserContact != undefined && !data.currentUserContact.email.endsWith('@ppg.com')">
      <label *transloco="let t" class="formLabel">{{ t('contact_selector-customer_contact_self') }}</label>
  </mat-radio-button>-->
        <mat-radio-button class="contact-radio-button" value="customerContact" *ngIf="data.customerContacts.length>0">
          <label *transloco="let t" class="formLabel">{{ t('contact_selector-customer_contact_existing') }}</label>
        </mat-radio-button>
        <mat-radio-button class="contact-radio-button" value="newContact">
          <label *transloco="let t" class="formLabel">{{ t('contact_selector-customer_contact_new') }}</label>
        </mat-radio-button>
      </div>
        <div *ngIf="contactTypeControl.value === 'customerContact'">
            <mat-form-field appearance="standard"  class="formField">
                <mat-label *transloco="let t">{{ t('contact_selector-customer_contact_existing') }}</mat-label>
                <mat-select [formControl]="selectCustomerContact">
                    <mat-option *ngFor="let contact of data.customerContacts" [value]="contact">
                        {{contact.firstName}} {{contact.lastName}} - {{contact.email}} 
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="selectCustomerContact.hasError('required')">
                    <span *transloco="let t">{{ t('contact_selector-please_select_contact') }}</span>
                    </mat-error>
            </mat-form-field>
        </div>
        <div *ngIf="contactTypeControl.value === 'newContact'">
            <mat-form-field appearance="standard"  class="formField">
                <mat-label *transloco="let t">{{ t('contact_selector-first_name') }}</mat-label>
                <input mat-input type="text" 
                matInput 
                [formControl]="newContactFirstName">
                <mat-error *ngIf="newContactFirstName.hasError('required')">
                    <span *transloco="let t">{{ t('contact_selector-please_select_contact_first_name') }}</span>
                    </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard"  class="formField">
                <mat-label *transloco="let t">{{ t('contact_selector-last_name') }}</mat-label>
                <input mat-input type="text" 
                matInput 
                [formControl]="newContactLastName">
                <mat-error *ngIf="newContactLastName.hasError('required')">
                    <span *transloco="let t">{{ t('contact_selector-please_select_contact_last_name') }}</span>
                    </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard"  class="formField">
                <mat-label *transloco="let t">{{ t('contact_selector-email') }}</mat-label>
                <input mat-input type="text" 
                matInput 
                [formControl]="newContactEmail">
                <mat-error *ngIf="newContactEmail.hasError('required')">
                    <span *transloco="let t">{{ t('contact_selector-please_select_contact_email') }}</span>
                </mat-error>
                <mat-error *ngIf="newContactEmail.hasError('email') && !newContactEmail.hasError('required')">
                    <span *transloco="let t">{{ t('contact_selector-please_select_contact_valid_email') }}</span>
                </mat-error>
            </mat-form-field>
        </div>
    </mat-radio-group>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="cancelClick()" *transloco="let t">{{ t('contact_selector-cancel') }}</button>
    <button mat-button *transloco="let t" [disabled]="!validContact" [mat-dialog-close]="{contactType: contactTypeControl.value, contact: selectedContact} ">{{ t('contact_selector-confirm') }}</button>
</div>

