import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, mergeMap, Observable, withLatestFrom } from 'rxjs';
import { CustomerSubscription } from 'src/app/shared/collections/customer-subscription.model';
import { CustomerService } from 'src/app/shared/customer/services/customer.service';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import { SubscriptionService } from 'src/app/shared/subscription/services/subscription.service';

@Component({
  selector: 'app-activate-subscription-page',
  templateUrl: './activate-subscription-page.component.html',
  styleUrls: ['./activate-subscription-page.component.scss']
})
export class ActivateSubscriptionPageComponent implements OnInit {
  @Input() customerSubscription!: CustomerSubscription;

  constructor(private subscriptionService: SubscriptionService,
    private customerService: CustomerService,
    private route: ActivatedRoute,
    private errorHandler: ErrorHandlerService) { }

  ngOnInit(): void {
    this.getSubscriptionAndCustomer();
  }

  getSubscriptionAndCustomer(): void{
    const id = String(this.route.snapshot.paramMap.get('id'));

    const subscription = this.subscriptionService.getSubscription(id);
    subscription.pipe(
      mergeMap(subscription => this.customerService.getCustomer(subscription.customerIdCRM)),
      withLatestFrom(subscription),
      map(([customer, subscription]) => {
        return {
          customer: customer,
          subscription: subscription
        }
      })
    ).subscribe({
      next: customerSubscription=>this.customerSubscription = customerSubscription,
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });
  }
}
