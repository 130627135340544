
<div class="container">
    <div class="controls">
        
        <div>
            <a mat-button routerLink="/home" color="basic">
                <mat-icon class="toolbar-icon">home</mat-icon>
            </a>
        </div>
        <mat-divider></mat-divider>
        <div>
            <button mat-button type="button" class="navButton" (click)="ZoomIn()" alt="Zoom In">
                <mat-icon>zoom_in</mat-icon>
            </button>    
        </div>
        <div>
            <button mat-button type="button" class="navButton" (click)="ZoomOut()" alt="Zoom Out">
                <mat-icon>zoom_out</mat-icon>
            </button>
        </div>
        <div *ngIf="loaded==totalLoad">
            <button mat-button type="button" class="navButton" (click)="Download()" alt="Download">
                <mat-icon>file_download</mat-icon>
            </button>
        </div>
        
        <mat-divider></mat-divider>
        <div>
            <app-language-selector color="accent" [activeLang]="activeLang" [availableLangs]="availableLangs" (selectedLang)="change($event)"></app-language-selector>
        </div>
        <div>
            <button mat-button color="basic" [matMenuTriggerFor]="fileMenu">
                <mat-icon>folder</mat-icon>
            </button>
            <mat-menu #fileMenu="matMenu">
                <span mat-menu-item  *ngFor="let file of selectablefiles" (click)="changeFile(file)">
                    <button mat-button [attr.data-cy]="file" (click)="changeFile(file)" [color]="currentfile === file ? 'primary' : 'basic'">
                        {{file}}
                    </button>
                </span>
                
            </mat-menu>
        </div>
        <div *ngIf="versions.length > 1">
            <button mat-button color="basic" [matMenuTriggerFor]="versionMenu">
                {{currentVersion}}
            </button>
            <mat-menu #versionMenu="matMenu">
                <span mat-menu-item  *ngFor="let version of versions" (click)="changeVersion(version)">
                    {{version}}
                </span>
                
            </mat-menu>
        </div>
        <mat-divider></mat-divider>
        <div  *ngIf="acceptanceList.length > 0">
            <button mat-button type="button" class="navButton" [matMenuTriggerFor]="acceptInfo">
                <mat-icon>assignment</mat-icon>
            </button>
            <mat-menu #acceptInfo="matMenu">
                <div *ngFor="let accept of acceptanceList">
                    <mat-divider></mat-divider>
                    <span *ngIf="accept.date != null"><span *transloco="let t">{{ t('tc_page-accepted_on') }}</span><span>{{accept.date}}</span></span><br/>
                    <span *transloco="let t">{{ t('tc_page-accepted_by') }}</span><span>{{accept.name}}</span><br/>
                    <span *ngIf="accept.date != null"><span>{{accept.email}}</span></span><br/>
                    <span *transloco="let t">{{ t('tc_page-subscription_desc') }}</span><span><i>{{accept.subscriptionDescription}}</i></span><br/>
                    <span *transloco="let t">{{ t('tc_page-version') }}</span><span>{{accept.version}}</span>
                    <mat-divider></mat-divider>
                </div>
                
            </mat-menu>
        </div>
        <!-- Create countries div -->
        <div *ngIf="countries.length > 1 ">
            <button mat-button color="basic" [matMenuTriggerFor]="countryMenu">
                <mat-icon>flag</mat-icon>
            </button>
            <mat-menu #countryMenu="matMenu">
                <span mat-menu-item  *ngFor="let country of countries" (click)="changeCountry(country)">
                    {{country | uppercase}}
                </span>
                
            </mat-menu>
            </div>  
    </div>
    <div class="loadingSpinner" *ngIf="(loaded==0||loaded<totalLoad)">
        <mat-spinner ></mat-spinner>
    </div>
    <pdf-viewer [src]="pdfSource"
        [render-text]="true"
        [original-size]="true"
        [zoom]="zoom"
        (error)="pdfError($event)"
        (on-progress)="onProgress($event)">
    </pdf-viewer>
    
</div>
