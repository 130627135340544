// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   postLogoutRedirectUri: 'http://localhost:4200',
//   clientId: '74528526-a466-4d51-985f-5c6dfd3f6b51',
//   knownAuthorities: ["ppgindustriesincb2cdev.b2clogin.com"],
//   authority: 'https://ppgindustriesincb2cdev.b2clogin.com/ppgindustriesincb2cdev.onmicrosoft.com/B2C_1_GSM_Dev_SginUpSignIn',
//   readScope: 'https://ppgindustriesincb2cdev.onmicrosoft.com/gsm-refinish-api-dev/gsm.read',
//   writeScope: 'https://ppgindustriesincb2cdev.onmicrosoft.com/gsm-refinish-api-dev/gsm.write'
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.


import { environment as localhost } from './environment.local';
import { environment as development } from './environment.dev';
import { environment as staging } from './environment.stg';
import { environment as production } from './environment.prod';

export const environment = getEnvironment();

function getEnvironment() {

  if (window.location.origin.includes("localhost")) {
    return localhost;
  } else if (window.location.origin.includes("subscriptionmanagementportaldev")) {
    return development;
  } else if (window.location.origin.includes("subscriptionmanagementportaltst")) {
    return staging;
  } else if (window.location.origin.includes("subscriptionmanagementportalprd") || window.location.origin.includes("subscriptions.ppgrefinish.com")) {
    return production;
  } else {
    return localhost;
  }
}