import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, first, retry, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../interfaces/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _baseUrl = environment.apiBaseURL + "Admin/User";
  constructor(private http: HttpClient) { }

  get(): Observable<User> {
    return this.http.get<User>(this._baseUrl+"/Get", {withCredentials: true}).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  addCustomerId(userEmail: string, customerId: string): Observable<any>{

    return this.http.post<any>(this._baseUrl+"/External/AddCustomerId", {userEmail: userEmail, customerId: customerId}, {withCredentials: true}).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      }));
  }

}
