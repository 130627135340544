import { Pipe, PipeTransform } from '@angular/core';
import { Customer } from '../interfaces/customer.model';

@Pipe({
  name: 'customerDisplay'
})
export class CustomerDisplayPipe implements PipeTransform {

  transform(customer: Customer): string {
    if (customer.id == "-1") {
      return customer.name;
    }
    
    var customerAddress = customer?.address != null ? ", "  + customer.address.city 
    + ", " 
    + customer.address.state
    + ", "
    + customer.address.postalCode : "";

    return customer.name 
      + "-" 
      + customer.id 
      +customerAddress      
      + " (" + customer.status + ")";
  }

}
