import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, Observable, Subscription, take } from 'rxjs';
import { ErrorHandlerService } from '../../navigation/services/error-handler.service';

import { Language } from '../interfaces/language.model';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private subscription: Subscription = Subscription.EMPTY;
  availableLangs: Language[] = [];
  availableLangsString: String[] = [];
  private currentLanguage = new BehaviorSubject<string>('en');

  constructor(private translocoService: TranslocoService,
    private errorHandler: ErrorHandlerService) { }

  ngOnInit(): void {
    
  }

  initialize(): void {
    this.availableLangs = this.translocoService.getAvailableLangs().map((s) => {
      if(typeof s === 'string'){
        return{ id: s, label: s}
      }
      return { id: s.id, label: s.label}
    });
    var defaultLanguage = navigator.language.substring(0,2);
    this.change(defaultLanguage);
    this.currentLanguage.next(defaultLanguage);
  }

  public returnLanguages(): Language[] {
    return this.availableLangs;
  }

  change(lang: string) {
    // Ensure new active lang is loaded
    var currLang = ""
    this.availableLangs.forEach(l => this.availableLangsString.push(l.id))
    if(this.availableLangsString.includes(lang)){
      currLang = lang;
    }
    else{
      currLang = "en"
    }
    this.subscription.unsubscribe();
    this.subscription = this.translocoService
      .load(currLang)
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.translocoService.setActiveLang(currLang);
          this.currentLanguage.next(currLang);
        },
        error: (err: HttpErrorResponse) => {
          // If language not found, set English.
          this.translocoService.setActiveLang("en");
          this.currentLanguage.next("en");
        }
    });
  }

  getCurrentLanguage(): Observable<string>{
    return this.currentLanguage.asObservable();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
