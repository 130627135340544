<app-toolbar [pageName]="t('accounts_page-title')" *transloco="let t"></app-toolbar>
<!-- <button mat-raised-button color="accent" routerLink="/subscription/dashboard" type="button" class="navButton">
    <span  *transloco="let t">{{ t('subscription_dashboard_page-title') }}</span>
    <mat-icon class="toolbar-icon">dashboard</mat-icon>
</button> -->
<!-- <button mat-raised-button color="accent" routerLink="/crm/dashboard" [disabled]="true" type="button" class="navButton">
    <span  *transloco="let t">{{ t('accounts_page-title') }}</span>
    <mat-icon class="toolbar-icon">dashboard</mat-icon>
</button> -->
<div class="main">
    <mat-card class="filter-card">
        <mat-card-content>
            <mat-form-field appearance="fill" class="searchbar">
                <mat-label><span *transloco="let t">{{ t('accounts_page-active_search') }}</span></mat-label>
                <input matInput type="text" (keyup)="applyCustomerFilter($event)" [disabled]="showLoading">
                <mat-icon matSuffix>
                    search
                </mat-icon>
                <div *ngIf="showSearchLoading">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
            </mat-form-field>
        </mat-card-content>
    </mat-card>
    <div class="table-div">
        
        <table mat-table [dataSource]="displayedPage" class="mat-elevation-z8 recordsTable" >
            <ng-containter matColumnDef="customerId">
                <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('accounts_page-customer_id_num') }}</span></th>
                <td mat-cell *matCellDef="let record" class="recordsCell">{{ record.paymentCustomer?.crmId ?? ""}}</td>
            </ng-containter>
    
            <ng-containter matColumnDef="customerName">
                <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('accounts_page-company_name') }}</span></th>
                <td mat-cell *matCellDef="let record" class="recordsCell">
                    {{ record.customer?.name ?? ""}}
                </td>
            </ng-containter>
    
            <ng-containter matColumnDef="customerAddress">
                <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('accounts_page-address') }}</span></th>
                <td mat-cell *matCellDef="let record" class="recordsCell">
                    {{ record.customer?.address | addressDisplay}}
                </td>
            </ng-containter>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="recordsHeader"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="recordRow" (click)="goToAccount(row)"></tr>
        </table>
        <div *ngIf="showLoading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
        <mat-paginator #paginator [length]="displayedColumns.length" 
            (page)="handlePageEvent($event)"
            [length]="displayedCustomers.length"
            [pageSize]="pageSize"
            [pageIndex]="pageIndex"
            [disabled]="showLoading"
            [showFirstLastButtons]="false"
            [pageSizeOptions]="[3, 5, 10, 15, 30]"
            [hidePageSize]="false"
            ></mat-paginator>
    </div>    
</div>

