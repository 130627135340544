import {Injectable, Inject} from '@angular/core';
import{ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable, map } from 'rxjs';
import { LoginFlowService } from '../../login-check/login-flow.service';


@Injectable()
export class IsInternal implements CanActivate {
    constructor(private loginFlowService: LoginFlowService,
      private router: Router) {}

    canActivate(
      route: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
    ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
      return this.loginFlowService.getUser().pipe(map((user=>{
        if(!user.external){
          return true;
        }else{
          return this.router.createUrlTree(['404']);
        }
      })));
    }
}