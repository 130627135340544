import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, retry, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SubscriptionCheckout } from '../interfaces/subscription-checkout.model';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {
  private _baseUrl = environment.apiBaseURL + "Common/Checkout";
  constructor(private http: HttpClient) { }

  getCheckout(transactionId: string): Observable<SubscriptionCheckout>{
    return this.http.get<SubscriptionCheckout>(this._baseUrl+"/GetCheckout?transactionId=" + transactionId, {withCredentials: true}).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }
}
