import { Component,Inject,OnInit } from '@angular/core';
import { SubscriptionService } from 'src/app/shared/subscription/services/subscription.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslocoService, setValue } from '@ngneat/transloco';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';

@Component({
    selector: 'app-subscriptions-dashboard-cancel-subscription.component',
    templateUrl: './subscriptions-dashboard-cancel-subscription.component.html',
    styleUrls: ['./subscriptions-dashboard-cancel-subscription.component.scss']
  })  

  export class SubscriptionsCancelPageComponent implements OnInit {
    loading: boolean = false;

    constructor(public dialogRef: MatDialogRef<SubscriptionsCancelPageComponent>,
        @Inject(MAT_DIALOG_DATA) 
        public data: string,
        private _snackBar: MatSnackBar,        
        private translocoService: TranslocoService,
        private errorHandler: ErrorHandlerService,
        private subscriptionService: SubscriptionService) { }

    ngOnInit(): void {
    }

    CancelSubscription(){
      this.loading = true;
      this.subscriptionService.cancelSubscription(this.data)
        .subscribe({
         next: result => {
            if (result)
            {
              this._snackBar.open(this.translocoService.translate('subscription_dashboard_details_page-cancel_subscription_success'), this.translocoService.translate('general_message_dismiss'));
              this.dialogRef.close();
            }
            else
            {
              this._snackBar.open(this.translocoService.translate('subscription_dashboard_details_page-cancel_subscription_error'), this.translocoService.translate('general_message_dismiss'));
            }
            this.loading = false;
         },
         error: (err: HttpErrorResponse) => {
           this.errorHandler.handleError(err);
           this._snackBar.open(this.translocoService.translate('general_message_error'), this.translocoService.translate('general_message_dismiss'));
           this.loading = false;
         }
       }); 
    }
  }