<div class="main-div">
	<form>
        <div>
            <div class="cancelSubscriptionFormSection">
                <div class="information-section">
                    <div>
                        <label *transloco="let t" class="section-header">{{t('subscription_dashboard_page_cancel-confirmation_title')}}</label>
                    </div>
                    <br>
                    <div class="information-details">
                        <div class="information-segment">
                            <label *transloco="let t">{{t('subscription_dashboard_page_cancel-confirmation_message')}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="actionButtonsSection" *transloco="let t">
                <button mat-raised-button color="warn" (click)="CancelSubscription()" [disabled]="loading">
                    <span>{{t('subscription_dashboard_page_cancel-confirmation_cancel_button')}}</span>                    
                </button>&nbsp;
                <button mat-raised-button class="discardButton" [mat-dialog-close]="true" [disabled]="loading">                    
                    <span>{{t('subscription_dashboard_page_cancel-confirmation_discard_button')}}</span>
                </button>
            </div>
            <br>
            <div *ngIf="loading">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
        </div>
    </form>
</div>