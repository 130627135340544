import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import { SubscriptionService } from 'src/app/shared/subscription/services/subscription.service';
import { SubscriptionDashboardRecord } from '../interfaces/subscription-dashboard-record.module';
import { AddressDisplayPipe } from 'src/app/shared/customer/pipes/address-display.pipe';
import { DatePipe } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { interval, takeWhile } from 'rxjs';
import { LoginFlowService } from 'src/app/shared/login-check/login-flow.service';
import { User } from 'src/app/shared/user/interfaces/user.model';

@Component({
  selector: 'app-subscriptions-dashboard-export',
  templateUrl: './subscriptions-dashboard-export.component.html',
  styleUrls: ['./subscriptions-dashboard-export.component.scss']
})
export class SubscriptionsDashboardExportComponent implements OnInit {
  records: SubscriptionDashboardRecord[] = [];
  distributorView: boolean = true;
  datePipe!: DatePipe;
  gettingPendingSubs = false;
  gettingActiveSubs = false;
  currentUser!: User;
  startAfterId: string = "";
  private readonly _destroying$ = new Subject<void>();
  constructor(public dialogRef: MatDialogRef<SubscriptionsDashboardExportComponent>,
    private errorHandler: ErrorHandlerService,
    private addressDisplayPipe: AddressDisplayPipe,
    private subscriptionService: SubscriptionService,
    private loginFlowService: LoginFlowService) { }

  ngOnInit(): void {
    this.datePipe = new DatePipe('en-US');
  }

  checkLogin(): void {
    this.loginFlowService.getUser().subscribe({
      next: user => {
        this.currentUser = user;
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });
  }

  exportAll(): void {
    this.checkLogin();
    this.startAfterId = "";
    this.gettingPendingSubs = true;
    this.subscriptionService.getPendingSubscriptionSummaries().pipe(takeUntil(this._destroying$)).subscribe({
    next: customers =>
      {
        customers.forEach(customer =>
        {
          customer.subscriptions.forEach(subscription =>
          {
            if (subscription.crmParentId != null && subscription.crmParentId != '')
            {
              this.distributorView = false;
            }
            this.records.push({ customer: customer, subscription: subscription });
          });
        });
        this.gettingActiveSubs = true;  
        this.exportActiveSubscriptions(true);
      },
      error: (err: HttpErrorResponse) =>
      {
        this.errorHandler.handleError(err);
      }
    });
  }

  exportActiveSubscriptions(includeUnpaid:boolean)
  {
    this.subscriptionService.getSubscriptionSummaries(500, this.startAfterId, includeUnpaid).pipe(takeUntil(this._destroying$)).subscribe({
      next: customers => {
        customers.forEach(customer => {
          customer.subscriptions.forEach(subscription => {
            if (subscription.crmParentId != null && subscription.crmParentId != '') {
              this.distributorView = false;
            }
            this.records.push({ customer: customer, subscription: subscription });
            if (subscription.id.includes("sub"))
            {
              this.startAfterId = subscription.id;
            }
          });
        });
        if (customers.length > 0)
          {
            this.exportActiveSubscriptions(false);
          }
          else
          {
            this.DownloadTable();
          }
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });
  }

  cancel(): void {
    this._destroying$.next();
    this._destroying$.complete();
    this.dialogRef.close();
  }

  DownloadTable() {
    var a = document.createElement("a");
    var contents = this.distributorView ?
      "customerId,customerName,customerCountry,customerBillingAddress,customerShippingAddress,subscriptionStatus,erpOrderNumber,paymentEntered,subscriptionDescription,subscriptionContact,subscriptionSubmittedBy,subscriptionCreated\n\"" :
      "customerId,customerName,customerCountry,customerBillingAddress,customerShippingAddress,subscriptionStatus,erpOrderNumber,paymentEntered,3rdPartyPaying,3rdPartyPayingName,subscriptionDescription,subscriptionContact,subscriptionSubmittedBy,subscriptionCreated\n\"";
    let areAllTheSameCustomer = true;
    let firstCustomerId = '';

    this.records.forEach((record, index) => {
      if (index == 0)
        firstCustomerId = record.subscription.crmCustomerId
      else if (firstCustomerId != record.subscription.crmCustomerId)
        areAllTheSameCustomer = false

      contents = contents
        + (this.distributorView ? record.subscription.crmCustomerId : record.customer.crmId) + "\",\""
        + (this.distributorView ? record.subscription.customerName : record.customer.name) + "\",\""
        + record.customer.country + "\",\""
        + this.addressDisplayPipe.transform(record.customer.billingAddress) + "\",\""
        + this.addressDisplayPipe.transform(record.customer.shippingAddress) + "\",\""
        + record.subscription.status + "\",\""
        + record.subscription.erpOrderNumber + "\",\""
        + record.subscription.paymentType + "\",\""
        + (!this.distributorView ? record.subscription.crmParentId + "\",\"" : "")
        + (!this.distributorView ? record.subscription.parentName + "\",\"" : "")
        + record.subscription.description + "\",\""
        + record.subscription.contact + "\",\""
        + record.subscription.submittedBy + "\",\""
        + this.datePipe.transform(record.subscription.created.toString(), 'dd/MM/YYYY')  + "\",\""
        + "\"\n\"";
    });

    a.href = URL.createObjectURL(new Blob([contents], { type: 'text/csv' }));
    a.download = "SubscriptionDashboard" + (areAllTheSameCustomer ? this.getComplementaryDownloadNamingInformation() : "") + ".csv";

    a.click();
    this.dialogRef.close();
  }

  getComplementaryDownloadNamingInformation() {
    let extraNamingInformation = "";

    if (this.records.length > 0) {
      extraNamingInformation = "ForCRM" + this.records[0].customer.crmId
    }

    return extraNamingInformation;
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

}
