import { DataSource } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, debounceTime, distinctUntilChanged, filter, map, mergeMap } from 'rxjs';
import { Customer } from 'src/app/shared/customer/interfaces/customer.model';
import { AddressDisplayPipe } from 'src/app/shared/customer/pipes/address-display.pipe';
import { CustomerService } from 'src/app/shared/customer/services/customer.service';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import { Subscription } from 'src/app/shared/subscription/interfaces/subscription.model';
import { SubscriptionService } from 'src/app/shared/subscription/services/subscription.service';
import { User } from 'src/app/shared/user/interfaces/user.model';
import { CustomerDashboardDetailsPageComponent } from './customer-dashboard-details-page/customer-dashboard-details-page.component';
import { CustomerService as PaymentCustomerService} from 'src/app/shared/payment/services/customer.service';
import { Customer as PaymentCustomer} from 'src/app/shared/payment/interfaces/customer.model';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { CustomerDashboardRecord } from './interfaces/customer-dashboard-record.model';


@Component({
  selector: 'app-customer-dashboard-page',
  templateUrl: './customer-dashboard-page.component.html',
  styleUrls: ['./customer-dashboard-page.component.scss']
})
export class CustomerDashboardPageComponent implements OnInit {
  customers: CustomerDashboardRecord[] = [];
  displayedCustomers: CustomerDashboardRecord[] = [];
  displayedPage: CustomerDashboardRecord[] = [];
  pageSize: number = 5;
  pageIndex: number = 0;
  reachedEnd: boolean = false;


  displayedColumns: string[] = ['customerId', 'customerName', 'customerAddress'];
  showLoading: boolean = true;
  currentUser: User | null = null;
  filterValue$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  filterValue: string = "";
  showSearchLoading = false;

  constructor(private subscriptionService: SubscriptionService,
    private customerService: CustomerService, 
    private paymentCustomerService: PaymentCustomerService,
    private addressDisplayPipe: AddressDisplayPipe,
    private detailsDialog: MatDialog,
    private errorHandler: ErrorHandlerService) { }

  ngOnInit(): void {
    this.getCustomers("");
    this.getCustomerFilter();
  }


  getCustomers(startAfterCustomerId: string): void{
    this.showLoading = true;
    this.paymentCustomerService.getCustomers(startAfterCustomerId, this.pageSize*2)
    .subscribe({
      next: customers=> {
        this.getCrmCustomers(customers);
        this.customers = this.customers.concat(customers.map(customer=>{
          return {customer: null, paymentCustomer: customer, subscriptions: []}
        })).slice();
        if(customers.length == 0 || (customers.length > this.pageIndex*this.pageSize && customers.length < (this.pageIndex+1)*this.pageSize)){
          this.reachedEnd = true;
        }
      },
      error: (err: HttpErrorResponse) =>{
      this.errorHandler.handleError(err);
      }
    });
    
  }

  getCrmCustomers(paymentCustomers: PaymentCustomer[]): void{
    this.customerService.getCustomersByIds(paymentCustomers.map(customer=> customer.crmId)).subscribe({
      next: customers=> {
        for(var i= 0; i<customers.length; i+=1){
          var record = this.customers.find(record=> record.paymentCustomer?.crmId == customers[i].id);
          if(record != null){
            record.customer = customers[i];
          }
        }
        this.displayedCustomers = this._customerFilter(this.filterValue).slice();
        this.getCurrentPage();
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });
  }

  loadMoreCustomers(): void{
    var lastRecordId = this.customers[this.customers.length-1].paymentCustomer?.id;
    if(lastRecordId != null){
      this.getCustomers(lastRecordId);
    }
  }

  getCustomerFilter(){
    this.filterValue$.pipe(
      debounceTime(3000),
      distinctUntilChanged(),
      filter(value=> value.length >=2),
    ).subscribe(filterValue=>{
      this.showSearchLoading = false;
      this.filterValue = filterValue;
      this.displayedCustomers = this._customerFilter(filterValue).slice();
      this.getCurrentPage();
    })
   
  }

  applyCustomerFilter(event: Event){
    this.showSearchLoading = true;
    const filterValue = (event.target as HTMLInputElement).value;
    this.filterValue$.next(filterValue.trim().toLowerCase());
    
  }

  private _customerFilter(value: string): CustomerDashboardRecord[] {
    if(value.length <3){
      return this.customers;
    }
    else{
      const filterValue = value.toLowerCase();

      return this.customers.filter(record => record.paymentCustomer?.crmId.toLowerCase().includes(filterValue) 
        || record?.customer?.name.toLowerCase().includes(filterValue));
    }
  }

  openCustomerDetailsDialog(record: CustomerDashboardRecord): void{
    this.showLoading = true;
    if(record.paymentCustomer!= null){
      this.subscriptionService.getSubscriptionsForCustomer(record.paymentCustomer?.crmId).subscribe({
        next: subscriptions=> {
          this.showLoading = false;
          record.subscriptions = subscriptions;
          const dialogRef = this.detailsDialog.open(CustomerDashboardDetailsPageComponent, {
            width: '90vw',
            data: record
          });
        },
        error: (err: HttpErrorResponse) => {
          this.errorHandler.handleError(err);
        }
      })
    }    
  }

  handlePageEvent(e: PageEvent) {
    if(this.pageSize != e.pageSize){
      this.pageIndex = 0;
      this.pageSize = e.pageSize;
    }else{
      this.pageIndex = e.pageIndex;
    }
    this.getCurrentPage();

  }

  getCurrentPage(): void{
    var startIndex = this.pageIndex*this.pageSize;
    var endIndex = this.pageIndex*this.pageSize+this.pageSize;
    this.displayedPage = this.displayedCustomers.slice(startIndex, endIndex > this.displayedCustomers.length ? this.displayedCustomers.length : endIndex);
    if(!this.reachedEnd && (this.pageIndex*this.pageSize) >= this.displayedCustomers.length-this.pageSize){
      this.pageIndex = this.pageIndex;
      this.loadMoreCustomers();
    }else{
      this.showLoading = false;
    }
  }
}
