import { Injectable } from '@angular/core';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Contact } from '../interfaces/contact.model';
 
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private _baseUrl = environment.apiBaseURL + "CRM/Contact";
  constructor(private http: HttpClient) { }

  getContacts(customerIdCRM: string): Observable<Contact[]>{
    return this.http.get<Contact[]>(this._baseUrl+"/List?customerId="+customerIdCRM, {withCredentials: true}).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }
}
