import { Pipe, PipeTransform } from '@angular/core';
import { Price } from '../interfaces/price.model';
import { getCurrencySymbol } from '@angular/common';
import { Payment } from '../../payment/interfaces/payment.model';

@Pipe({
  name: 'currencySymbol'
})
export class CurrencySymbolPipe implements PipeTransform {

  transform(priceOrPayment: Price | Payment): string {
    var symbol
    symbol = getCurrencySymbol(priceOrPayment.currency,'narrow',undefined)
    return symbol
  }

}
