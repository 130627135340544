import { Component, OnInit } from '@angular/core';
import { LoginFlowService } from '../login-flow.service';
import { ErrorHandlerService } from '../../navigation/services/error-handler.service';
import { SubscriptionService } from '../../subscription/services/subscription.service';
import { SubscriptionCheckout } from '../../payment/interfaces/subscription-checkout.model';
import { HttpErrorResponse } from '@angular/common/http';
import { CurrencySymbolPipe } from '../../subscription/pipes/currency-symbol.pipe';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-accept-terms-and-conditions',
  templateUrl: './accept-terms-and-conditions.component.html',
  styleUrls: ['./accept-terms-and-conditions.component.scss']
})
export class AcceptTermsAndConditionsComponent implements OnInit {
  checkouts: SubscriptionCheckout[] = [];
  loading: boolean = true;
  displayedColumns: string[] = ['customerId', 'customerName', 'subscriptionDescription', 'price', 'quantity', 'total', 'termsAndConditions', 'accept'];

  constructor(private subscriptionService: SubscriptionService,
    private errorHandler: ErrorHandlerService,
    private loginFlowService: LoginFlowService,
    private currencySymbolPipe: CurrencySymbolPipe) { }

    ngOnInit(): void {
      this.getPendingCheckouts();
    }
  
    getPendingCheckouts(): void{
      this.loginFlowService.getPendingCheckouts().subscribe({
        next: checkouts=> {
          var stripeCheckouts = checkouts.filter(checkout=> !checkout.acceptInStripe && checkout.termsAndConditions != null);
          this.loading = false;
  
          stripeCheckouts.forEach((checkout) => {           
            if(checkout.termsAndConditions.tcFilePath)
            {
              checkout.termsAndConditions.tcFilePath = environment.customTermsAndConditionsUrl + '/' + checkout.termsAndConditions.tcFilePath;
            }
            if (checkout.crmParentId != null && checkout.crmParentId != '' && parseInt(checkout.price.amountDecimal) == 0) {
              this.displayedColumns = ['customerId', 'customerName', 'parent', 'subscriptionDescription', 'termsAndConditions', 'accept']
            }
            else if (checkout.crmParentId != null && checkout.crmParentId != '') {
              this.displayedColumns = ['customerId', 'customerName', 'parent', 'subscriptionDescription', 'price', 'termsAndConditions', 'accept']
            }
            else if (checkout.crmParentId != null && checkout.crmParentId != '' && checkout.quantity > 1) {
              this.displayedColumns = ['customerId', 'customerName', 'parent', 'subscriptionDescription', 'price', 'quantity', 'total', 'termsAndConditions', 'accept']
            }
          });
  
          this.checkouts = stripeCheckouts;
        },
        error: (err: HttpErrorResponse) => {
          this.errorHandler.handleError(err);
        }
      });
    }

    acceptClick(checkout: SubscriptionCheckout){
      this.loading = true;
      this.subscriptionService.acceptSubscriptionTermsAndConditions(checkout).subscribe(()=>{
        this.refreshClick();
      });
      
    }
  
    refreshClick(): void{
      this.loading = true;
      this.loginFlowService.refreshSubscriptionCheckouts();
    }

  getCurrencySymbols = (checkout: SubscriptionCheckout) => {
    return this.currencySymbolPipe.transform(checkout.price);
  }

}
