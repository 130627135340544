<nav>
    <a mat-button routerLink="/home" color="basic">
        <span  *transloco="let t">{{ t('toolbar-home') }}</span>
        <mat-icon class="toolbar-icon">home</mat-icon>
    </a>
    <button mat-button color="basic" [matMenuTriggerFor]="pdfMenu">
        <mat-icon>help</mat-icon>
    </button>
    <mat-menu #pdfMenu="matMenu">
        <!-- <span mat-menu-item  *ngFor="let lang of availableLangs" (click)="change(lang.id)">
            <button mat-button [attr.data-cy]="lang.id" (click)="change(lang.id)" [color]="activeLang === lang.id ? 'primary' : 'basic'" class="lang-button">
                <span *transloco="let t">
                    {{ t('language_selector-'+lang.label.toLowerCase()) }}
                </span>
            </button>
        </span> -->
        <span mat-menu-item routerLink="/terms-conditions">
            <button mat-button>
                <span *transloco="let t">
                    {{ t('toolbar-terms_conditions') }}
                </span>
            </button>
        </span>
        <span mat-menu-item (click)="openContactHelpInformation()">
            <button mat-button>
                <span *transloco="let t">
                    {{ t('contact_page-title') }}
                </span>
            </button>
        </span>
    </mat-menu>
    <app-language-selector [activeLang]="activeLang" [availableLangs]="availableLangs" (selectedLang)="change($event)"></app-language-selector>
    <button mat-button color="basic">
        <mat-icon class="toolbar-icon" (click) = 'logout()'>
            exit_to_app
        </mat-icon>
    </button>
</nav>