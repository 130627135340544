<app-toolbar [pageName]="t('activate_subscription_page-title')" *transloco="let t"></app-toolbar>
<div class="main-div">
    <div *ngIf="customerSubscription != null; else progressSpinner">
        <app-subscription-activation [customerSubscription]="customerSubscription"></app-subscription-activation>
    </div>
    <ng-template #progressSpinner>
        <div class="spinner">
            <mat-spinner></mat-spinner>
        </div>
        
    </ng-template>
</div>
