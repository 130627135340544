export const environment = {
    production: true,
    postLogoutRedirectUri: 'https://subscriptionmanagementportaltst.azurewebsites.net',
    clientId: '74528526-a466-4d51-985f-5c6dfd3f6b51',
    knownAuthorities: ["ppgindustriesincb2cdev.b2clogin.com"],
    authority: 'https://ppgindustriesincb2cdev.b2clogin.com/ppgindustriesincb2cdev.onmicrosoft.com/B2C_1_GSM_Dev_SignUpSignIn',
    readScope: 'https://ppgindustriesincb2cdev.onmicrosoft.com/gsm-refinish-api-dev/gsm.read',
    writeScope: 'https://ppgindustriesincb2cdev.onmicrosoft.com/gsm-refinish-api-dev/gsm.write',
    apiBaseURL: 'https://subscriptionmanagementportalapitst.azurewebsites.net/api/',
    apiDomain: 'https://subscriptionmanagementportalapitst.azurewebsites.net',
    tstBanner: true,
    termsAndConditionsUrl: 'https://strefgsmstrgptst01.blob.core.windows.net/termsandconditions',
    customTermsAndConditionsUrl: 'https://strefgsmstrgptst01.blob.core.windows.net/customtermsandconditions'
  };