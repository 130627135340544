<app-toolbar [pageName]="customer.name + '(' + customer.crmId + ')'" *transloco="let t"></app-toolbar>
<!-- <button mat-raised-button color="accent" routerLink="/subscription/dashboard" [disabled]="true" type="button" class="navButton">
    <span  *transloco="let t">{{ t('account_page-title') }}</span>
    <mat-icon class="toolbar-icon">dashboard</mat-icon>
</button>
<button mat-raised-button color="accent" routerLink="/crm/dashboard"  type="button" class="navButton">
    <span  *transloco="let t">{{ t('customer_dashboard_page-title') }}</span>
    <mat-icon class="toolbar-icon">dashboard</mat-icon>
</button> -->
<div class="accountButtons">
    <button mat-raised-button type="button" class="accountButton" *ngIf="currentUser?.external==true&&billingPortalUrl!=''" (click)="manageAccountClick()">
        <span *transloco="let t" >{{ t('account_page-manage_account') }} </span> <mat-icon>launch</mat-icon>
    </button>
    <button mat-raised-button type="button"  class="accountButton" *ngIf="billingPortalUrl==''&&currentUser?.external==true">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </button>
</div>
<div class="main">
    <div class="mat-elevation-z8 table-div">
        <div class="table-div-inner">
        
            <table mat-table [dataSource]="displayedPage" class="recordsTable" >
                <ng-containter matColumnDef="customerId">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('account_page-customer_id_num') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.crmCustomerId ?? customer.crmId ?? "" }}
                    </td>
                </ng-containter>
        
                <ng-containter matColumnDef="customerName">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('account_page-company_name') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.customerName ?? customer.name ?? "" }}
                    </td>
                </ng-containter>
    
                <ng-containter matColumnDef="customerCountry">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('account_page-country') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ customer.country ?? "" }}
                    </td>
                </ng-containter>
        
                <ng-containter matColumnDef="customerBillingAddress">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('account_page-billing_address') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ customer.billingAddress | addressDisplay }}
                    </td>
                </ng-containter>
    
                <ng-containter matColumnDef="customerShippingAddress">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('account_page-shipping_address') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ customer.shippingAddress | addressDisplay }}
                    </td>
                </ng-containter>

                <ng-containter matColumnDef="subscriptionStatus">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('account_page-subscription_status') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.status | uppercase }} 
                    </td>
                </ng-containter>

                <ng-containter matColumnDef="erpOrderNumber">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('account_page-subscription_erpOrderNumber') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.erpOrderNumber }} 
                    </td>
                </ng-containter>
    
                <ng-containter matColumnDef="customerPaymentEntered">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t" class="mat-header-cell-small">{{ t('account_page-payment_entered') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.paymentType | uppercase }}
                    </td>
                </ng-containter>

                <ng-containter matColumnDef="parentId">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('account_page-paid_by_id') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.crmParentId ?? "" }}
                    </td>
                </ng-containter>

                <ng-containter matColumnDef="parentName">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('account_page-paid_by_customer') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.parentName ?? "" }}
                    </td>
                </ng-containter>
    
                <ng-containter matColumnDef="subscriptionId">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('account_page-subscription_id') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.id ?? "" }}
                    </td>
                </ng-containter>
    
                <ng-containter matColumnDef="subscriptionDescription">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('account_page-subscription_description') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.description }} 
                    </td>
                </ng-containter>
    
                <ng-containter matColumnDef="subscriptionContact">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('account_page-subscription_contact') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.contact }} 
                    </td>
                </ng-containter>

                <ng-containter matColumnDef="subscriptionSubmittedBy">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('account_page-subscription_submitted_by') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.submittedBy }} 
                    </td>
                </ng-containter>
    
                <ng-containter matColumnDef="hardwareShipped">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t" class="mat-header-cell-small">{{ t('account_page-hardware_shipped') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCellCenter">
                        <mat-checkbox [checked]="record.hardwareShipped" [disabled]="true"></mat-checkbox>
                    </td>
                </ng-containter>  

                <ng-containter matColumnDef="subscriptionCreated">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('account_page-subscription_created') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ (record.paidBy === 1 ? record.paidForCreated:record.created)  | date:'fullDate' }} 
                    </td>
                </ng-containter>
    
                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="recordsHeader"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="recordRow"  (click)="openSubscriptionDetailsDialog(row)"></tr>
            </table>
            <div *ngIf="showLoading">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            
        </div>
        <mat-paginator #paginator [length]="displayedColumns.length" 
                (page)="handlePageEvent($event)"
                [length]="records.length"
                [pageSize]="pageSize"
                [pageIndex]="pageIndex"
                [disabled]="showLoading"
                [showFirstLastButtons]="false"
                [pageSizeOptions]="[3, 5, 10, 15, 30]"
                [hidePageSize]="false"
                ></mat-paginator>
    </div>
</div>

