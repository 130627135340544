import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import { User } from 'src/app/shared/user/interfaces/user.model';
import { LoginFlowService } from '../../shared/login-check/login-flow.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})

export class HomePageComponent implements OnInit {
  currentUser: User | null = null;
  validLogin: boolean = true;
  spinnerWhileLoading = true;

  constructor(private errorHandler: ErrorHandlerService,
    private loginFlowService: LoginFlowService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.checkLogin();
  }

  checkLogin(): void{
    this.loginFlowService.getUser().subscribe({
      next: user=>{
        this.currentUser = user;
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });
    
  }

  navigateToAccount(): void{
    if(this.currentUser != null && this.currentUser.authorizations.length ==1 && this.currentUser.authorizations[0].customerId!="ALL_CUSTOMERS"){
      this.router.navigate(['/account',this.currentUser.authorizations[0].customerId]);
    }else{
      this.router.navigate(['/accounts']);
    }
    
  }
}
