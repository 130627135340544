import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, first, retry, catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TCFile } from '../interfaces/tcFile.model';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  private _baseUrl = environment.apiBaseURL + "Admin/File";
  constructor(private http: HttpClient) { }

  getTermsAndConditions(): Observable<TCFile[]> {
    return this.http.get<TCFile[]>(this._baseUrl+"/TermsAndConditionsList", {withCredentials: true}).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  // get terms and conditions by language 
  getTermsAndConditionsByLanguage(language: string): Observable<TCFile[]> {
    return this.http.get<TCFile[]>(this._baseUrl+"/TermsAndConditionsCountries/"+language, {withCredentials: true}).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }
}
