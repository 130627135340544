<div *ngIf="scheduleSteps.length<1">
    <span *transloco="let t">{{ t('new_subscription_page-schedule_select_currency') }}</span>
</div>
<table mat-table [dataSource]="scheduleSteps" class="mat-elevation-z8 details-table">
    <!-- Description Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('schedule-description') }}</span></th>
      <td mat-cell *matCellDef="let step">{{step.description}}</td>
    </ng-container>
  
    <!-- Amount Column -->
    <ng-container matColumnDef="subtotal">
      <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('schedule-amount') }}</span></th>
      <td mat-cell *matCellDef="let step">{{getCurrencySymbols(step)}} {{step.amountDecimal | number:'1.2-2'}}</td>
    </ng-container>
  
    <!-- Start Column -->
    <ng-container matColumnDef="startDate">
      <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('schedule-start_date') }}</span></th>
      <td mat-cell *matCellDef="let step">{{step.schedule.startDate | date:'fullDate'}}</td>
    </ng-container>
  
    <!-- End Column -->
    <ng-container matColumnDef="endDate">
      <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('schedule-end_date') }}</span></th>
      <td mat-cell *matCellDef="let step">{{step.schedule.endDate | date:'fullDate'}}</td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>