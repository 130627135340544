import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Customer } from '../interfaces/customer.model';
import { TaxId } from '../interfaces/tax-id.model';
@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private _baseUrl = environment.apiBaseURL + "Payment/Customer";
  constructor(private http: HttpClient) { }
  
  getCustomer(customerId: string): Observable<Customer>{
    return this.http.get<Customer>(this._baseUrl+"/Get?id=" + customerId, {withCredentials: true}).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  getCustomerByCrmId(customerId: string): Observable<Customer>{
    return this.http.get<Customer>(this._baseUrl+"/GetByCrmId?crmId=" + customerId, {withCredentials: true}).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  getCustomers(startAfterCustomerId: string, limit: number): Observable<Customer[]>{
    return this.http.get<Customer[]>(this._baseUrl+"/List?startAfterCustomer="+(startAfterCustomerId ?? "") + "&limit="+(limit ?? ""), {withCredentials: true}).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  getCustomersByCrmIds(customerCrmIds: string[]): Observable<Customer[]>{
    return this.http.post<Customer[]>(this._baseUrl+"/GetListByCrmId", customerCrmIds, {withCredentials: true}).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  setTax(taxId: TaxId): Observable<TaxId>{
    return this.http.post<TaxId>(this._baseUrl+"/SetTax", taxId, {withCredentials: true}).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  validateTax(taxId: TaxId): Observable<TaxId>{
    return this.http.post<TaxId>(this._baseUrl+"/ValidateTax", taxId, {withCredentials: true}).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  removeTax(taxId: TaxId): Observable<TaxId>{
    return this.http.delete<TaxId>(this._baseUrl+"/RemoveTax?taxId=" + taxId.id + "&customerId=" + taxId.customerId, {withCredentials: true}).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  GetBillingPortalLink(customerId: string): Observable<string>{
    return this.http.get<string>(this._baseUrl+"/GetBillingPortal?customerId="+customerId,{responseType: 'text' as 'json', withCredentials: true}).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }
}
