
<!-- <app-toolbar [pageName]="t('page_error-title')" *transloco="let t"></app-toolbar> -->
<app-toolbar></app-toolbar>


<div class="main-div">
    <mat-card class="home-card basic-card mat-elevation-z8">
        <p>
            {{headerMessage}}
        </p>
        <p *ngIf="showErrorDetails"> 
            {{errorMessage}} 
        </p>
    </mat-card>
</div>
