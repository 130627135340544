export const environment = {
  production: true,
  postLogoutRedirectUri: 'https://subscriptions.ppgrefinish.com',
  clientId: 'e40ef787-2bf2-4949-8c5f-164a2e8cc9f1',
  knownAuthorities: ["ppgindustriesincb2cprod.b2clogin.com"],
  authority: 'https://ppgindustriesincb2cprod.b2clogin.com/ppgindustriesincb2cprod.onmicrosoft.com/B2C_1_GSM_Prod_SignUpSignIn',
  readScope: 'https://ppgindustriesincb2cprod.onmicrosoft.com/gsm-refinish-api-prd/gsm.read',
  writeScope: 'https://ppgindustriesincb2cprod.onmicrosoft.com/gsm-refinish-api-prd/gsm.write',
  apiBaseURL: 'https://subscriptionmanagementportalapiprd.azurewebsites.net/api/',
  apiDomain: 'https://subscriptionmanagementportalapiprd.azurewebsites.net',
  tstBanner: false,
  termsAndConditionsUrl: 'https://strefgsmstrgpprd01.blob.core.windows.net/termsandconditions',
  customTermsAndConditionsUrl: 'https://strefgsmstrgpprd01.blob.core.windows.net/customtermsandconditions'
};