export enum CountryList{ //country list by ISO apha2 code
    fr,
    gf,
    gp,
    mq,
    nc,
    pf,
    re,
    yt,
    ar,
    au,
    bo,
    ec,
    gt,
    id,
    jp,
    kp,
    kr,
    my,
    mp,
    mx,
    ph,
    cn,
    sr,
    ch,
    tw,
    th,
    ag,
    ai,
    an,
    aw,
    bb,
    bm,
    bs,
    bz,
    do,
    gd,
    gu,
    gy,
    ht,
    jm,
    ky,
    tt,
    um,
    us,
    vi,
    ws,
    ca,
    ad,
    es,
    pt,
    tr,
    it,
    mt,
    sm,
    br,
    de,
    gb,
    ie,
    hk,
    in,
    cr,
    sv,
    hn,
    pa,
    at,
    ni,
    uy,
    nz,
    cz,
    hu,
    lt,
    lv,
    md,
    pl,
    ro,
    sk,
    ua,
    by,
    kg,
    kz,
    ru,
    tj,
    tm,
    uz,
    dk,
    ee,
    fi,
    is,
    no,
    se,
    be,
    lu,
    nl,
    cl,
    ae,
    ao,
    bf,
    bh,
    bi,
    cg,
    ci,
    cm,
    dz,
    eg,
    et,
    ga,
    gh,
    il,
    iq,
    jo,
    ke,
    kw,
    lb,
    lr,
    ly,
    ma,
    mg,
    ml,
    mr,
    mu,
    ne,
    ng,
    om,
    qa,
    sa,
    sd,
    sl,
    sn,
    so,
    sy,
    tg,
    tn,
    tz,
    ug,
    ye,
    zm,
    al,
    am,
    az,
    ba,
    bg,
    cy,
    ge,
    gr,
    hr,
    mk,
    rs,
    si,
    xk,
    co,
    na,
    za,
    bd,
    bn,
    kh,
    py,
    pe,
    ve,
    la,
    mm,
    np,
    pk,
    sg,
    lk,
    vn
}