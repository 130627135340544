import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { map, mergeMap } from 'rxjs';
import { CustomerSubscription } from 'src/app/shared/collections/customer-subscription.model';
import { AddressDisplayPipe } from 'src/app/shared/customer/pipes/address-display.pipe';
import { CustomerService } from 'src/app/shared/customer/services/customer.service';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import { SubscriptionService } from 'src/app/shared/subscription/services/subscription.service';
import { ActivateSubscriptionDialogComponent } from '../activate-subscription-dialog/activate-subscription-dialog.component';


@Component({
  selector: 'app-incomplete-subscriptions-page',
  templateUrl: './incomplete-subscriptions-page.component.html',
  styleUrls: ['./incomplete-subscriptions-page.component.scss']
})
export class IncompleteSubscriptionsPageComponent implements OnInit {
  incompleteSubscriptions: CustomerSubscription[] = [];
  displayedColumns: string[] = ['customerId', 'customerName', 'customerAddress', 'subscriptionName', 'dateSubmitted', 'status', 'newTab'];
  showLoading: boolean = true;
  
  constructor(private subscriptionService: SubscriptionService,
    private customerService: CustomerService, 
    private addressDisplayPipe: AddressDisplayPipe,
    private activateSubscriptionDialog: MatDialog,
    private windowWidthObserver: BreakpointObserver,
    private router: Router,
    private errorHandler: ErrorHandlerService) { }

  ngOnInit(): void {
    this.adjustDisplayedColumns();
    this.getIncompleteSubscriptionsAndCustomers();
  }

  adjustDisplayedColumns(): void{
    this.windowWidthObserver.observe([
      "(max-width: 768px)"
    ]).subscribe({
        next: (result: BreakpointState) => {
        if (result.matches) {
          this.displayedColumns = ['customerId', 'customerName','subscriptionName', 'status', 'newTab'];   
        } else {
          this.displayedColumns = ['customerId', 'customerName', 'customerAddress', 'subscriptionName', 'dateSubmitted', 'status', 'newTab'];
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });
  }

  getIncompleteSubscriptionsAndCustomers(): void{
    const customers = this.customerService.getCustomersForUser();
    const subscriptions = customers.pipe(mergeMap(customers=> this.subscriptionService.getIncompleteSubscriptions(customers.map(customer=> customer.id)))); 
    
    
    customers.pipe(mergeMap(customers=> subscriptions.pipe(map(subscriptions=> {
      const customerSubscriptions: CustomerSubscription[] = [];
      customers.forEach(customer=>{
        subscriptions.filter(sub=> sub.customerIdCRM === customer.id).forEach(sub=>{
          customerSubscriptions.push({customer: customer, subscription: sub});
        })
      })
      return customerSubscriptions 
    })))).subscribe({
        next: subs=> {
        this.incompleteSubscriptions = subs;
        this.showLoading = false;
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });
  }

  sortSubscriptions(sort: Sort){
    const data = this.incompleteSubscriptions.slice();

    if(!sort.active || sort.direction === ''){
      this.incompleteSubscriptions = data;
      return;
    }

    this.incompleteSubscriptions = data.sort((a,b) => {
      const isAsc = sort.direction === 'asc';
      switch(sort.active){
        case "customerId":
          return this._compare(a.subscription.customerIdCRM, b.subscription.customerIdCRM, isAsc);
        case "customerName":
          return this._compare(a.customer.name, a.customer.name, isAsc);
        case "customerAddress":
          return this._compare(this.addressDisplayPipe.transform(a.customer.address),this.addressDisplayPipe.transform(b.customer.address), isAsc);
        case "subscriptionName":
          return this._compare(a.subscription.description, b.subscription.description, isAsc);
        case "dateSubmitted":
          return this._compare(a.subscription.created, b.subscription.created, isAsc);
        case "status":
          return this._compare(a.subscription.status, b.subscription.status, isAsc);
        default:
          return 0;
      }
    });
  }

  private _compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  openActivateSubscriptionDialog(record: CustomerSubscription): void{
    const dialogRef = this.activateSubscriptionDialog.open(ActivateSubscriptionDialogComponent, {
      width: '90vw',
      data: record
    });
    dialogRef.afterClosed().subscribe({
        next: result => {
        // this.contactControl.setValue(result);
        // this.contactControl.markAsTouched();
        // this.selectedContact = result.contact;
        // this.selectedContactType = result.contactType;
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });
  }

  openActivateSubscriptionDialogNewTab(record: CustomerSubscription): void{
// Converts the route into a string that can be used 
  // with the window.open() function
  const url = this.router.serializeUrl(
    this.router.createUrlTree([`/activate-subscription/${record.subscription.id}`])
  );

  window.open(url, '_blank');
  }

}
