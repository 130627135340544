<div *ngIf="loading; else dialogDiv" >
    <mat-progress-bar mode="indeterminate" value="40"></mat-progress-bar>
</div>
<ng-template #dialogDiv>
    <h1 mat-dialog-title *transloco="let t" >{{ t('accept_terms_and_conditions-title') }}</h1>
    <div mat-dialog-content class="dialogContent">
        <div class="messageLabel">
            <span *transloco="let t">{{ t('accept_terms_and_conditions-message') }}</span>
        </div>
        <div class="mat-elevation-z8 table-div">
            <div class="table-div-inner">
            
                <table mat-table [dataSource]="checkouts" class="recordsTable" >
                    <ng-containter matColumnDef="customerId">
                        <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('accept_terms_and_conditions-customer_id') }}</span></th>
                        <td mat-cell *matCellDef="let checkout" class="recordsCell">{{ checkout.crmCustomerId ?? ""}}</td>
                    </ng-containter>
            
                    <ng-containter matColumnDef="customerName">
                        <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('accept_terms_and_conditions-customer_name') }}</span></th>
                        <td mat-cell *matCellDef="let checkout" class="recordsCell">
                            {{ checkout.customerName ?? ""}}
                        </td>
                    </ng-containter>

                    <ng-containter matColumnDef="parent">
                        <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('accept_terms_and_conditions-distributor') }}</span></th>
                        <td mat-cell *matCellDef="let checkout" class="recordsCell">
                            {{checkout.parentName ?? "" }} ({{checkout.crmParentId ?? ""}})
                        </td>
                    </ng-containter>
        
                    <ng-containter matColumnDef="subscriptionDescription">
                        <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('accept_terms_and_conditions-subscription') }}</span></th>
                        <td mat-cell *matCellDef="let checkout" class="recordsCell">
                            {{ checkout.subscriptionDescription ?? ""}}
                        </td>
                    </ng-containter>

                    <ng-containter matColumnDef="price">
                        <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('accept_terms_and_conditions-price') }}</span></th>
                        <td mat-cell *matCellDef="let checkout" class="recordsCell">
                            {{checkout.price.currency}} | {{getCurrencySymbols(checkout)}} {{checkout.price.amountDecimal | number:'1.2-2'}}
                        </td>
                    </ng-containter>

                    <ng-containter matColumnDef="quantity">
                        <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('accept_terms_and_conditions-quantity') }}</span></th>
                        <td mat-cell *matCellDef="let checkout" class="recordsCell">
                            {{checkout.quantity}}
                        </td>
                    </ng-containter>

                    <ng-containter matColumnDef="total">
                        <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('accept_terms_and_conditions-grandTotal') }}</span></th>
                        <td mat-cell *matCellDef="let checkout" class="recordsCell">
                            {{getCurrencySymbols(checkout)}} {{checkout.price.amountDecimal * checkout.quantity | number:'1.2-2'}}
                        </td>
                    </ng-containter>

                    <ng-containter matColumnDef="termsAndConditions">
                        <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('accept_terms_and_conditions-terms_and_conditions') }}</span></th>
                        <td mat-cell *matCellDef="let checkout" class="recordsCell">
                            <a *ngIf="!checkout.termsAndConditions.tcFilePath" [routerLink]="['/terms-conditions', {file: checkout.termsAndConditions?.fullPath}]" target="_blank">
                                <span *transloco="let t">
                                    {{ t('accept_terms_and_conditions-view') }}
                                </span>
                            </a>
                            <a *ngIf="checkout.termsAndConditions.tcFilePath" [href]="checkout.termsAndConditions?.tcFilePath" target="_blank">
                                <span *transloco="let t">
                                   <b> {{ t('accept_terms_and_conditions-view') }}</b>
                                </span>
                            </a>
                        </td>
                    </ng-containter>
        
                    <ng-containter matColumnDef="accept">
                        <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('accept_terms_and_conditions-accept_header') }}</span></th>
                        <td mat-cell *matCellDef="let checkout" class="recordsCell">
                            <button mat-raised-button color="accent" *transloco="let t" (click)="acceptClick(checkout)" class="actionButton">
                                {{ t('accept_terms_and_conditions-accept')}}
                            </button>
                        </td>
                    </ng-containter>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="recordsHeader"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="recordRow"></tr>
                </table>                
            </div>
        </div>
        <div class="actionButtons">
            <button mat-raised-button color="accent" *transloco="let t" (click)="refreshClick()" class="actionButton">
                {{ t('accept_terms_and_conditions-refresh')}}
            </button>
        </div>
    </div>
</ng-template>
