<mat-card>
    <mat-card-content>
        <div *ngIf="!gettingPendingSubs && !gettingActiveSubs">
            <span  *transloco="let t" >{{ t('subscription_dashboard_page-export_warning') }}</span>
        </div>
        <div *ngIf="gettingPendingSubs && !gettingActiveSubs">
            <span  *transloco="let t" >{{ t('subscription_dashboard_page-getting_pending') }}</span>
        </div>
        <div *ngIf="gettingPendingSubs && gettingActiveSubs">
            <span  *transloco="let t" >{{ t('subscription_dashboard_page-getting_active') }}</span>
        </div>
        <div *ngIf="gettingPendingSubs || gettingActiveSubs">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
    </mat-card-content>
    <mat-card-actions align="start">
        <button mat-raised-button color="accent" type="button" class="navButton" (click)="exportAll()" *ngIf="!gettingPendingSubs && !gettingActiveSubs">
            <span  *transloco="let t">{{ t('subscription_dashboard_page-export_all_continue') }}</span>
        </button>
        <button mat-raised-button color="accent" type="button" class="navButton" (click)="cancel()">
            <span  *transloco="let t">{{ t('subscription_dashboard_page-export_all_cancel') }}</span>
        </button>
    </mat-card-actions>
</mat-card>