import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';
import { User } from 'src/app/shared/user/interfaces/user.model';
import { UserAuthService } from 'src/app/shared/user/services/user-auth.service';
import { LoginFlowService } from './login-flow.service';
import { LoginState } from './login-state.enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-check',
  templateUrl: './login-check.component.html',
  styleUrls: ['./login-check.component.scss']
})
export class LoginCheckComponent implements OnInit {
  state: LoginState = LoginState.Initial;
  currentUser!: User;
  loading = true;


  constructor(public dialogRef: MatDialogRef<LoginCheckComponent>,
    private loginFlowService: LoginFlowService,
    private msalService: MsalService) { }

  ngOnInit(): void {
    this.checkState();
    this.checkUserInput();
  }

  checkState(): void{
    this.loginFlowService.getState().subscribe(state=>{
      // if(state == LoginState.Complete){
      //   this.dialogRef.close();
      // }
      this.state = state;
    })
  }

  checkUserInput(): void{
    this.loginFlowService.isUserInputRequired().subscribe(inputRequired=> this.loading = !inputRequired);
  }

  checkUser(): void{
    this.loginFlowService.getUser().subscribe(user=> this.currentUser = user);
  }

  completeSetup(): void{
    this.dialogRef.close();
  }

  logout(): void{
    this.msalService.logoutRedirect({
      postLogoutRedirectUri: environment.postLogoutRedirectUri
    });
  }
}
