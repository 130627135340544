import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerSubscription } from '../../../collections/customer-subscription.model';
import { Contact } from '../../../customer/interfaces/contact.model';
import { ErrorHandlerService } from '../../../navigation/services/error-handler.service';
import { Price } from '../../../subscription/interfaces/price.model';

@Component({
  selector: 'app-subscription-activation',
  templateUrl: './subscription-activation.component.html',
  styleUrls: ['./subscription-activation.component.scss']
})
export class SubscriptionActivationComponent implements OnInit {
@Input() customerSubscription!: CustomerSubscription
subscriptionPrices: Price[]=[];
selectedSubscriptionPrice!: Price;
subscriptionContact!: Contact;
noPrice: boolean = false;
paymentMethods: string[]=[];
selectedPaymentMethod: string = "";
paymentMethodFormControl!: FormControl;
paymentPeriodFormControl!: FormControl;
subscriptionFormControl!: FormControl;
activationFormGroup!: FormGroup;


  constructor(private errorHandler: ErrorHandlerService) { }

  ngOnInit(): void {
      this.getCustomerSubscription();
  }

  getCustomerSubscription(): void{
    this.initSubscriptionPrice();
    this.initSubscriptionPaymentMethod();
    this.initSubscriptionContact();
    this.initFormControls();
  }

  initSubscriptionPrice(): void{
    // this.subscriptionPrices = this.customerSubscription.subscription.product.price.filter(price=> price.active);

    // if(this.subscriptionPrices.length === 0){
    //   this.noPrice = true;
    // }else{
    //   this.selectedSubscriptionPrice = this.subscriptionPrices[0];
    // }
    //TODO: get subscription prices
  }

  initSubscriptionPaymentMethod(): void{
    //might want to make this dynamic later
    this.paymentMethods = ["Credit Card", "ERP Invoice"];
    this.selectedPaymentMethod = this.paymentMethods[0];
  }

  initSubscriptionContact(): void{
    if(this.customerSubscription!= null){
      const subscriptionContact = this.customerSubscription.subscription.additionalInfo
      .filter(info=>info.key==='contact')[0]?.value ?? "";
      this.subscriptionContact = this.customerSubscription.customer.contacts
        .filter(customer=> customer.email === subscriptionContact)[0];
    }
  }

  initFormControls(): void{
    this.paymentMethodFormControl = new FormControl<string>(this.selectedPaymentMethod, Validators.required);
    this.paymentPeriodFormControl = new FormControl<Price | string>(this.selectedSubscriptionPrice, Validators.required);
    this.subscriptionFormControl = new FormControl(this.customerSubscription?.subscription, Validators.required);
    this.activationFormGroup = new FormGroup({
      subscriptionFormControl: this.subscriptionFormControl,
      paymentMethodFormControl: this.paymentMethodFormControl,
      paymentPeriodFormControl: this.paymentPeriodFormControl
    });

    if(this.noPrice){
      this.paymentMethodFormControl.disable();
      this.paymentPeriodFormControl.disable();
    }else{
      this.paymentPeriodFormControl.valueChanges.subscribe({
        next: value=> this.selectedSubscriptionPrice = value,
        error: (err: HttpErrorResponse) => {
          this.errorHandler.handleError(err);
        }
      });
    }
  }
}
