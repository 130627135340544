import { Component, OnInit, Input } from '@angular/core';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Language } from '../../../../language/interfaces/language.model';
import { MsalService } from '@azure/msal-angular';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from '../../../services/error-handler.service';
import { UserAuthService } from '../../../../user/services/user-auth.service';
import { LoginFlowService } from '../../../../login-check/login-flow.service';
import { LoginState } from '../../../../login-check/login-state.enum';
import { MatDialog } from '@angular/material/dialog';
import { LoginCheckComponent } from '../../../../login-check/login-check.component';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ContactHelpComponent } from '../../contact-help/contact-help.component';
import { LanguageService } from '../../../../language/services/language.service';
@Component({
  selector: 'app-toolbar-menu',
  templateUrl: './toolbar-menu.component.html',
  styleUrls: ['./toolbar-menu.component.scss']
})
export class ToolbarMenuComponent implements OnInit {
  availableLangs: Language[] = [];

  constructor(private translocoService: TranslocoService,
    private msalService: MsalService,
    private userAuthService: UserAuthService,
    private errorHandler: ErrorHandlerService,
    private loginFlowService: LoginFlowService,
    private loginDialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private contactHelpDialog: MatDialog,
    private languageService: LanguageService) { }

  ngOnInit(): void {
    this.getLanguages();
  }

  getLanguages(): void {
    this.availableLangs = this.languageService.returnLanguages();
  }

  get activeLang() {
    return this.translocoService.getActiveLang();
  }

  change(lang: string) {
    this.languageService.change(lang);
  }

  

  logout(): void{
    this.msalService.logoutRedirect({
      postLogoutRedirectUri: environment.postLogoutRedirectUri
    });
  }

  openContactHelpInformation(): void{
    const dialogRef = this.contactHelpDialog.open(ContactHelpComponent, {
      width: '50vw',
      minWidth: '500px'
    });
  }
}
