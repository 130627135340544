export interface TaxIdType {
    country: string
    enum: string,
    description: string
}

export const TAXIDTYPES: TaxIdType[] = [
    // {country: 'au', enum: 'au_abn', description: 'Australian Business Number (AU ABN)'},
    // {country: 'au', enum: 'au_arn', description: 'Australian Taxation Office Reference Number'},
    {country: 'at', enum: 'eu_vat', description: 'European VAT number'},
    {country: 'be', enum: 'eu_vat', description: 'European VAT number'},
    // {country: 'br', enum: 'br_cnpj', description: 'Brazilian CNPJ number'},
    // {country: 'br', enum: 'br_cpf', description: 'Brazilian CPF number'},
    // {country: 'bg', enum: 'bg_uic', description: 'Bulgaria Unified Identification Code'},
    {country: 'bg', enum: 'eu_vat', description: 'European VAT number'},
    {country: 'ca', enum: 'ca_bn', description: 'Canadian BN'},
    // {country: 'ca', enum: 'ca_gst_hst', description: 'Canadian GST/HST number'},
    // {country: 'ca', enum: 'ca_pst_bc', description: 'Canadian PST number (British Columbia)'},
    // {country: 'ca', enum: 'ca_pst_mb', description: 'Canadian PST number (Manitoba)'},
    // {country: 'ca', enum: 'ca_pst_sk', description: 'Canadian PST number (Saskatchewan)'},
    // {country: 'ca', enum: 'ca_qst', description: 'Canadian QST number (Québec)'},
    // {country: 'cl', enum: 'cl_tin', description: 'Chilean TIN'},
    {country: 'hr', enum: 'eu_vat', description: 'European VAT number'},
    {country: 'cy', enum: 'eu_vat', description: 'European VAT number'},
    {country: 'cz', enum: 'eu_vat', description: 'European VAT number'},
    {country: 'dk', enum: 'eu_vat', description: 'European VAT number'},
    //{country: 'eg', enum: 'eg_tin', description: 'Egyptian Tax Identification Number'},
    {country: 'ee', enum: 'eu_vat', description: 'European VAT number'},
    //{country: 'eu', enum: 'eu_oss_vat', description: 'European One Stop Shop VAT number for non-Union scheme'},
    {country: 'fi', enum: 'eu_vat', description: 'European VAT number'},
    {country: 'fr', enum: 'eu_vat', description: 'European VAT number'},
    //{country: 'ge', enum: 'ge_vat', description: 'Georgian VAT'},
    {country: 'de', enum: 'eu_vat', description: 'European VAT number'},
    {country: 'gr', enum: 'eu_vat', description: 'European VAT number'},
    //{country: 'hk', enum: 'hk_br', description: 'Hong Kong BR number'},
    //{country: 'hu', enum: 'hu_tin', description: 'Hungary tax number (adószám)'},
    {country: 'hu', enum: 'eu_vat', description: 'European VAT number'},
    //{country: 'is', enum: 'is_vat', description: 'Icelandic VAT'},
    //{country: 'in', enum: 'in_gst', description: 'Indian GST number'},
    //{country: 'id', enum: 'id_npwp', description: 'Indonesian NPWP number'},
    {country: 'ie', enum: 'eu_vat', description: 'European VAT number'},
    //{country: 'il', enum: 'il_vat', description: 'Israel VAT'},
    {country: 'it', enum: 'eu_vat', description: 'European VAT number'},
    //{country: 'jp', enum: 'jp_cn', description: 'Japanese Corporate Number (*Hōjin Bangō*)'},
    //{country: 'jp', enum: 'jp_rn', description: "Japanese Registered Foreign Businesses' Registration Number (*Tōroku Kokugai Jigyōsha no Tōroku Bangō*)"},
    //{country: 'jp', enum: 'jp_trn', description: 'Japanese Tax Registration Number (*Tōroku Bangō*)'},
    //{country: 'ke', enum: 'ke_pin', description: 'Kenya Revenue Authority Personal Identification Number'},
    {country: 'lv', enum: 'eu_vat', description: 'European VAT number'},
    //{country: 'li', enum: 'li_uid', description: 'Liechtensteinian UID number'},
    {country: 'lt', enum: 'eu_vat', description: 'European VAT number'},
    {country: 'lu', enum: 'eu_vat', description: 'European VAT number'},
    //{country: 'my', enum: 'my_frp', description: 'Malaysian FRP number'},
    //{country: 'my', enum: 'my_itn', description: 'Malaysian ITN'},
    //{country: 'my', enum: 'my_sst', description: 'Malaysian SST number'},
    {country: 'mt', enum: 'eu_vat', description: 'European VAT number'},
    //{country: 'mx', enum: 'mx_rfc', description: 'Mexican RFC number'},
    {country: 'nl', enum: 'eu_vat', description: 'European VAT number'},
    //{country: 'nz', enum: 'nz_gst', description: 'New Zealand GST number'},
    {country: 'no', enum: 'no_vat', description: 'Norwegian VAT number'},
    //{country: 'ph', enum: 'ph_tin', description: 'Philippines Tax Identification Number'},
    {country: 'pl', enum: 'eu_vat', description: 'European VAT number'},
    {country: 'pt', enum: 'eu_vat', description: 'European VAT number'},
    {country: 'ro', enum: 'eu_vat', description: 'European VAT number'},
    //{country: 'ru', enum: 'ru_inn', description: 'Russian INN'},
    //{country: 'ru', enum: 'ru_kpp', description: 'Russian KPP'},
    //{country: 'sa', enum: 'sa_vat', description: 'Saudi Arabia VAT'},
    //{country: 'sg', enum: 'sg_gst', description: 'Singaporean GST'},
    //{country: 'sg', enum: 'sg_uen', description: 'Singaporean UEN'},
    {country: 'sk', enum: 'eu_vat', description: 'European VAT number'},
    //{country: 'si', enum: 'si_tin', description: 'Slovenia tax number (davčna številka)'},
    {country: 'si', enum: 'eu_vat', description: 'European VAT number'},
    {country: 'za', enum: 'za_vat', description: 'South African VAT number'},
    //{country: 'kr', enum: 'kr_brn', description: 'Korean BRN'},
    //{country: 'es', enum: 'es_cif', description: 'Spanish NIF number (previously Spanish CIF number)'},
    {country: 'es', enum: 'eu_vat', description: 'European VAT number'},
    {country: 'se', enum: 'eu_vat', description: 'European VAT number'},
    {country: 'ch', enum: 'ch_vat', description: 'Switzerland VAT number'},
    // {country: 'tw', enum: 'tw_vat', description: 'Taiwanese VAT'},
    // {country: 'th', enum: 'th_vat', description: 'Thai VAT'},
    // {country: 'tr', enum: 'tr_tin', description: 'Turkish Tax Identification Number'},
    // {country: 'ua', enum: 'ua_vat', description: 'Ukrainian VAT'},
    // {country: 'ae', enum: 'ae_trn', description: 'United Arab Emirates TRN'},
    {country: 'gb', enum: 'gb_vat', description: 'United Kingdom VAT number'},
    {country: 'ir', enum: 'eu_vat', description: 'Northern Ireland VAT number'},
    {country: 'us', enum: 'us_ein', description: 'United States EIN'}
]
