import { Pipe, PipeTransform } from '@angular/core';
import { Contact } from '../interfaces/contact.model';

@Pipe({
  name: 'contactDisplay'
})
export class ContactDisplayPipe implements PipeTransform {

  transform(contact: Contact): string {
    return contact.firstName 
      + " " 
      + contact.lastName 
      + " - " 
      + contact.email;
  }

}
