import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, retry, tap, throwError } from 'rxjs';
import { Subscription } from '../interfaces/subscription.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { SubscriptionCreationSubmission } from '../interfaces/subscription-creation-submission.model';
import { CustomerSummary } from '../../payment/interfaces/customer-summary.model';
import { SubscriptionSummary } from '../../payment/interfaces/subscription-summary.model';
import { SubscriptionCheckout } from '../../payment/interfaces/subscription-checkout.model';
import { UpdateSubscriptionDetails } from '../../../pages/subscriptions-dashboard-page/interfaces/update-subscription-details.model';
import { Price } from '../interfaces/price.model';
import { TCFile } from 'src/app/shared/admin/interfaces/tcFile.model'
import { User } from 'src/app/shared/user/interfaces/user.model';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionService {
    //private subscriptionUrl = 'api/subscriptions/'
    private _baseUrl = environment.apiBaseURL + "Payment/Subscription";
    sub!: Subscription;

    constructor(private http: HttpClient) { }

    getSubscriptions(): Observable<Subscription[]>
    {
        return this.http.get<Subscription[]>(this._baseUrl + "/List", { withCredentials: true }).pipe(
            retry(2),
            catchError((error: HttpErrorResponse) =>
            {
                console.error(error);
                return throwError(() => error);
            })
        );
    }

    getSubscriptionSummaries(limit:number=-1, startAfterId:string = "", includeUnpaid:boolean = true): Observable<CustomerSummary[]> {
    return this.http.get<CustomerSummary[]>(this._baseUrl + "/ListSummaries"+(limit>-1 ? "?limit=" + limit.toString() : "")+"&exportSubscriptions=true"+(startAfterId != "" ? "&startAfterId=" + startAfterId : "")+"&includeUnpaid=" + includeUnpaid, { withCredentials: true }).pipe(
      catchError((error: HttpErrorResponse) => {
                console.error(error);
                return throwError(() => error);
            })
        );
    }

    getPendingSubscriptionSummaries(limit: number = -1): Observable<CustomerSummary[]>
    {
        return this.http.get<CustomerSummary[]>(this._baseUrl + "/ListSummaries/Pending" + (limit > -1 ? "?limit=" + limit.toString() : ""), { withCredentials: true }).pipe(
            catchError((error: HttpErrorResponse) =>
            {
                console.error(error);
                return throwError(() => error);
            })
        );
    }

    getSubscriptionSummariesForCustomer(customerCrmId: string): Observable<CustomerSummary[]>
    {
        return this.http.get<CustomerSummary[]>(this._baseUrl + "/ListSummaries/Customer?customerCrmId=" + customerCrmId, { withCredentials: true }).pipe(
            catchError((error: HttpErrorResponse) =>
            {
                console.error(error);
                return throwError(() => error);
            })
        );
    }

    getSubscriptionsForCustomer(customerCrmId: string): Observable<Subscription[]>
    {

        return this.http.get<Subscription[]>(this._baseUrl + "/ListForCrmId?crmId=" + customerCrmId, { withCredentials: true }).pipe(
            retry(2),
            catchError((error: HttpErrorResponse) =>
            {
                console.error(error);
                return throwError(() => error);
            })
        );
    }

    getIncompleteSubscriptions(customerIds: string[]): Observable<Subscription[]>
    {
        return this.getSubscriptions().pipe(
            map(subscriptions => subscriptions.filter(s => (s.status === "Pending Payment" || s.status === "Pending Activation") && customerIds.includes(s.customerIdCRM))),
            catchError((error: HttpErrorResponse) =>
            {
                console.error(error);
                return throwError(() => error);
            })
        );
    }

    getPendingSubscriptionCheckouts(crmIds: string[] = []): Observable<SubscriptionCheckout[]>
    {
        return this.http.post<SubscriptionCheckout[]>(this._baseUrl + "/ListPendingCheckouts", crmIds, { withCredentials: true }).pipe(
            retry(2),
            catchError((error: HttpErrorResponse) =>
            {
                console.error(error);
                return throwError(() => error);
            })
        );
    }

    getPendingSubscriptions(crmIds: string[] = []): Observable<SubscriptionSummary[]>
    {  //get customer subscriptions including Pending
        return this.http.post<SubscriptionSummary[]>(this._baseUrl + "/ListPending", crmIds, { withCredentials: true }).pipe(
            retry(2),
            catchError((error: HttpErrorResponse) =>
            {
                console.error(error);
                return throwError(() => error);
            })
        );
    }

    getSubscription(id: string): Observable<Subscription>
    {
        return this.getSubscriptions().pipe(
            map(subscriptions => subscriptions.filter(sub => sub.id === id)[0]),
            catchError((error: HttpErrorResponse) =>
            {
                console.error(error);
                return throwError(() => error);
            })
        );

    }

    createSubscription(subscriptionCreation: SubscriptionCreationSubmission): Observable<Subscription[]>
    {
        return this.http.post<Subscription[]>(this._baseUrl + "/CreateSubscription", subscriptionCreation, { withCredentials: true }).pipe(
            retry(2),
            catchError((error: HttpErrorResponse) =>
            {
                console.error(error);
                return throwError(() => error);
            })
        );
    }

    updateSubscription(updateSubscriptionDetails: UpdateSubscriptionDetails): Observable<Subscription>
    {
        return this.http.post<Subscription>(this._baseUrl + "/UpdateSubscription", updateSubscriptionDetails, { withCredentials: true }).pipe(
            retry(2),
            catchError((error: HttpErrorResponse) =>
            {
                console.error(error);
                return throwError(() => error);
            })
        );
    }

    acceptSubscriptionTermsAndConditions(subscriptionCheckout: SubscriptionCheckout): Observable<Subscription>
    {
        return this.http.post<Subscription>(this._baseUrl + "/AcceptTermsAndConditions", subscriptionCheckout, { withCredentials: true }).pipe(
            retry(2),
            catchError((error: HttpErrorResponse) =>
            {
                console.error(error);
                return throwError(() => error);
            })
        );
    }

    resendNewSubscriptionEmail(id: string, customerEmail: boolean): Observable<boolean>
    {
        return this.http.post<boolean>(this._baseUrl + "/ResendNewSubscriptionEmail?subscriptionId=" + id + "&customerEmail=" + customerEmail, { withCredentials: true }).pipe(
            retry(2),
            catchError((error: HttpErrorResponse) =>
            {
                console.error(error);
                return throwError(() => error);
            })
        );
    }

    getSubscriptionSchedule(id: string): Observable<Price[]>
    {
        return this.http.post<Price[]>(this._baseUrl + "/GetSubscriptionSchedule?subscriptionId=" + id, { withCredentials: true }).pipe(
            retry(2),
            catchError((error: HttpErrorResponse) =>
            {
                console.error(error);
                return throwError(() => error);
            })
        );
    }

    uploadTCFile(formData: FormData)
    {
        return this.http.post<{ path: string }>(this._baseUrl + "/UploadTAndCFile", formData, { withCredentials: true }).pipe(
            retry(1),
            catchError((error: HttpErrorResponse) =>
            {
                console.error(error);
                return throwError(() => error);
            })
        );
    }

    removeTCFile(formData: FormData)
    {
        return this.http.post<{ path: string }>(this._baseUrl + "/RemoveTAndCFile", formData, { withCredentials: true }).pipe(
            retry(1),
            catchError((error: HttpErrorResponse) =>
            {
                console.error(error);
                return throwError(() => error);
            })
        );
    }

    getTCFileFromSubscription(subscriptionId: string): Observable<TCFile>
    {
    return this.http.get<TCFile>(this._baseUrl + "/GetTCFileFromSubscription?subid=" + subscriptionId, { withCredentials: true }).pipe(
            retry(1),
            catchError((error: HttpErrorResponse) =>
            {
                console.error(error);
                return throwError(() => error);
            })
        );
    }

  cancelSubscription(id: string): Observable<boolean> {
    return this.http.post<boolean>(this._baseUrl+"/CancelUnchargedSubscription?id=" + id,  {withCredentials: true}).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }
}