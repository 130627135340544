<h1 mat-dialog-title *transloco="let t">{{ t('contact_page-title') }}</h1>
<div mat-dialog-content>
    <mat-label *transloco="let t">{{ t('contact_page-sales_rep') }}</mat-label>
    <ul>
        <li>
            <mat-label *transloco="let t">{{ t('contact_page-header_eu_uk') }}</mat-label>
            <ul>
                <li>
                    <mat-label *transloco="let t">{{ t('contact_page-header_support') }}{{ t('contact_page-eu_uk_support_email') }}</mat-label>
                </li>
            </ul>
        </li>
        <li>
            <mat-label *transloco="let t">{{ t('contact_page-header_us_us') }}</mat-label>
            <ul>
                <li>
                    <mat-label *transloco="let t">{{ t('contact_page-header_support') }}{{ t('contact_page-us_us_support_email') }}</mat-label>
                </li>
            </ul>
        </li>
        <li>
            <mat-label *transloco="let t">{{ t('contact_page-header_ca_ca') }}</mat-label>
            <ul>
                <li>
                    <mat-label *transloco="let t">{{ t('contact_page-header_support') }}{{ t('contact_page-ca_ca_support_email') }}</mat-label>
                </li>
            </ul>
        </li>
    </ul>
</div>