import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LoginFlowService } from 'src/app/shared/login-check/login-flow.service';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import { CheckoutService } from 'src/app/shared/payment/services/checkout.service';
import { filter, merge, skipUntil, skipWhile, switchMap } from 'rxjs';
import { LoginState } from 'src/app/shared/login-check/login-state.enum';
import { MatDialog } from '@angular/material/dialog';
import { LoginCheckComponent } from 'src/app/shared/login-check/login-check.component';
import { MatGridTileHeaderCssMatStyler } from '@angular/material/grid-list';

@Component({
  selector: 'app-checkout-page',
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.scss']
})
export class CheckoutPageComponent implements OnInit {
  checkoutId = "";
  redirectUrl = "";
  loadingWheel = true;
  errorState = false;
  loginDialogOpened = false;
  constructor(private route: ActivatedRoute,
    private loginFlowService: LoginFlowService,
    private checkoutService: CheckoutService,
    private errorHandler: ErrorHandlerService,
    private loginDialog: MatDialog,) { }

  ngOnInit(): void {
    this.initTransferToCheckout();
    this.checkLoginFlow();
  }

  initTransferToCheckout(){
    this.checkoutId = this.route.snapshot.params["id"];
    var checkout = this.checkoutService.getCheckout(this.checkoutId);

    checkout.subscribe({
      next: checkout=>{
        this.redirectUrl = checkout.url;
        this.loginFlowService.refreshUser();
      },
      error: (err: HttpErrorResponse) =>{
        this.errorState = true;
        //this.errorHandler.handleError(err);
        }
      });  

      this.loginFlowService.getState().pipe(skipWhile(state=> state!=LoginState.Complete)).subscribe(()=>{
        if(this.redirectUrl != "")
          window.location.href = this.redirectUrl;
      });
  }

  checkLoginFlow(){
    this.loginFlowService.waitingUserInput.pipe(filter(inputRequired=> inputRequired))
    .subscribe(()=>{
      if(!this.loginDialogOpened){
        this.loginDialogOpened = true;
        this.loginDialog.open(LoginCheckComponent,{
          width: '90vw',
          closeOnNavigation: false,
          disableClose: true
        }).afterClosed().subscribe(()=>{
          this.loginDialogOpened = false;
      })
      }
    });
  }

}
