<!-- <p>subscription-activation works!</p>
<p>
    <span>CUSTOMER</span>
    <span>{{customer.id}}</span>
    <span>{{customer.name}}</span>
    <span>{{customer.address | addressDisplay}}</span>
    <span>{{subscriptionContact}}</span>
    <span>SUBSCRIPTION</span>
    <span>{{subscription.id}}</span>
    <span>{{subscription.product.name}}</span>
    <span>{{subscriptionPrice | priceDisplay}}</span>
</p> -->

<div class="main-div">
    <form>
        <div class="information-section">
            <div>
                <label *transloco="let t" class="section-header">{{t('subscription_activation-customer_information_header')}}</label>
            </div>
            <div class="information-details">
                <div class="information-segment">
                    <label *transloco="let t">{{t('subscription_activation-customer_id')}}:</label>
                    <span>#{{customerSubscription.customer.id}}</span>
                </div>
                <div class="information-segment">
                    <label *transloco="let t">{{t('subscription_activation-company_name')}}: </label>
                    <span>{{customerSubscription.customer.name}}</span>
                </div>
                <br/>
                <div class="information-segment">
                    <label *transloco="let t">{{t('subscription_activation-address')}}: </label>
                    <span>{{customerSubscription.customer.address | addressDisplay}}</span>
                </div>
                <br/>
                <div class="information-segment">
                    <label *transloco="let t">{{t('subscription_activation-contact')}}: </label>
                    <span>{{subscriptionContact | contactDisplay}}</span>
                </div>
            </div>
        </div>
        <div class="information-section">
            <div>
                <label *transloco="let t" class="section-header">{{t('subscription_activation-subscription-information-header')}}</label>
            </div>
            <div class="information-details">
                <div class="information-segment">
                    <label *transloco="let t">{{t('subscription_activation-subscription_id')}}:</label>
                    <span>#{{customerSubscription.subscription.id}}</span>
                </div>
                <div class="information-segment">
                    <label *transloco="let t">{{t('subscription_activation-subscription_name')}}: </label>
                    <span>{{customerSubscription.subscription.description}}</span>
                </div>
                <br/>
                <div class="information-segment" *ngIf="!noPrice; else freeSegmentBlock">
                    <label *transloco="let t">{{t('subscription_activation-subscription_price')}}: </label>
                    <span>{{selectedSubscriptionPrice | priceDisplay}}</span>
                </div>
                <ng-template #freeSegmentBlock>
                    <div class="information-segment">
                        <label *transloco="let t">{{t('subscription_activation-subscription_price')}}: </label>
                        <span>FREE</span>
                    </div>
                </ng-template>
            </div>
        </div>
        <div class="information-section" *ngIf="!noPrice">
            <div>
                <label *transloco="let t" class="section-header">{{t('subscription_activation-payment_options')}}</label>
            </div>
            <div class="information-entry">
                <mat-form-field appearance="fill" class="formField">
                    <mat-label >
                        <span *transloco="let t">{{t('subscription_activation-method_of_payment')}}</span> 
                    </mat-label>
                    <mat-select [formControl]="paymentMethodFormControl">
                        <mat-option *ngFor="let method of paymentMethods" [value]="method">
                                {{method}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="information-entry">
                <mat-form-field appearance="fill" class="formField">
                    <mat-label >
                        <span *transloco="let t">{{t('subscription_activation-period_of_payment')}}</span> 
                    </mat-label>
                    <mat-select [formControl]="paymentPeriodFormControl">
                        <mat-option *ngFor="let price of subscriptionPrices"  [value]="price">
                            {{price.schedule.interval}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div>
            <div *ngIf="noPrice; else priceSubmit">
                <button mat-raised-button 
                color="primary" 
                [disabled]="!activationFormGroup.valid" >
                    <span  *transloco="let t" >
                        {{ t('subscription_activation-activate_subscription') }}
                    </span>
                </button>
            </div>
            <ng-template #priceSubmit>
                <button mat-raised-button 
                color="primary" 
                [disabled]="!activationFormGroup.valid" >
                    <span *transloco="let t">
                        {{ t('subscription_activation-continue_to_payment') }}
                    </span>
                </button>
            </ng-template>
        </div>
    </form>
</div>