import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, interval } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { LanguageService } from '../../shared/language/services/language.service';
import { Language } from '../../shared/language/interfaces/language.model';
import { PDFProgressData } from 'ng2-pdf-viewer';
import { MatDialog } from '@angular/material/dialog';
import { LoadingComponent } from '../../shared/navigation/components/loading/loading.component';
import { FileService } from 'src/app/shared/admin/services/file.service';
import { TCFile } from 'src/app/shared/admin/interfaces/tcFile.model';
import { CookieService } from 'ngx-cookie-service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Acceptance } from 'src/app/shared/admin/interfaces/acceptance.model';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent implements OnInit {
  pdfSource = this.pdfPath;
  zoom = 1;
  zoomOptions = [0.1, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 2, 3, 4];
  zoomIndexDefault = 4;
  zoomIndex = new BehaviorSubject<number>(this.zoomIndexDefault);
  availableLangs: Language[] = [];
  activeLang: string = "en";
  loaded = 0;
  totalLoad = 100;
  downloading = false;

  files: TCFile[] = [];
  acceptanceList: Acceptance[] = [];
  filePaths: string[] = [];
  selectablefiles: string[] = [];
  currentfile = "";
  currentfilePath = "";
  currentStripeAccount = "";
  currentVersion = "v1";
  currentCountry = "default";
  versions = ["v1"];
  countries: string[] = [];
  defaultAvailableFiles = ["linq_pro", "ppg_linq_pack_pro_flex"];
  customFile = false;

  constructor(private translocoService: TranslocoService,
    private languageService: LanguageService,
    private fileService: FileService,
    private loadingDialog: MatDialog,
    private route: ActivatedRoute,
    private cookieService: CookieService,
    private http: HttpClient) { }

  ngOnInit(): void {
    this.activeLang = this.translocoService.getActiveLang();
    this.loadingDialog.open(LoadingComponent,
      {
        closeOnNavigation: true,
        disableClose: true
      });
    this.updateZoom();
    this.getLanguages();
    this.getCurrentLanguagePDF();
    this.checkCookieFile();
    this.checkSubscriptions();
    this.getTermsAndConditionsByLanguage(this.activeLang);
  }

  updateZoom() {
    this.zoomIndex.subscribe(index => this.zoom = this.zoomOptions[index]);
  }

  getLanguages(): void {
    this.availableLangs = this.languageService.returnLanguages();
  }

  getCurrentLanguagePDF(): void {
    this.languageService.getCurrentLanguage().subscribe({
      next: language => {
        this.activeLang = language;
        this.pdfSource = this.pdfPath;
      }
    })
  }

  get pdfPath() {
    var language = this.activeLang;
    
    if (this.customFile == true){
      return environment.customTermsAndConditionsUrl + '/' + this.currentfile;
    }else{
    
      if (this.currentfile == "") {
        return "";
      } 
      else if (this.currentfile?.endsWith(".pdf")) {
        
        return environment.termsAndConditionsUrl + '/' + language + '/' + this.currentStripeAccount + '/' + this.currentCountry+ '/' + this.currentVersion + '/' + this.currentfile;
      }
      else {
        return environment.termsAndConditionsUrl + '/' + language + '/' + this.currentStripeAccount + '/' + this.currentCountry+ '/' + this.currentVersion + '/' + this.currentfile + ".pdf";
      }
  }
  }



  ZoomIn(): void {
    var index = this.zoomIndex.getValue();
    if (index < this.zoomOptions.length) {
      this.zoomIndex.next(index + 1);
    }
  }

  ZoomOut(): void {
    var index = this.zoomIndex.getValue();
    if (index > 0) {
      this.zoomIndex.next(index - 1);
    }
  }

  Download(): void {
    if (!this.downloading) {
      this.downloading = true;
      this.http.get(this.pdfSource, { responseType: "blob" })
        .subscribe((resp: any) => {
          var a = document.createElement("a");

          a.href = URL.createObjectURL(resp);
          a.setAttribute('download', this.activeLang + "_" + this.currentVersion + "_" + this.currentfile);
          a.click();
          this.downloading = false;
        });
    }
  }

  ZoomReset(): void {
    this.zoomIndex.next(this.zoomIndexDefault);
  }

  pdfError(error: any): void {
    if (error.name == 'MissingPDFException') {
      this.activeLang = 'en';
      this.pdfSource = this.pdfPath;
    }
  }

  change(lang: string) {
    this.loaded = 0;
    this.totalLoad = 0;
    this.getTermsAndConditionsByLanguage(lang);
    this.languageService.change(lang);
  }

  onProgress(progressData: PDFProgressData) {
    // do anything with progress data. For example progress indicator
    this.loaded = progressData.loaded;
    this.totalLoad = progressData.total;
  }

  changeFile(file: string) {
    this.currentfile = file;
    this.currentfilePath = file;
    this.versions = [... new Set(this.filePaths.filter(path => path.includes(file)).map(path => path.split("/")[2]).slice())];
    this.currentVersion = this.versions[this.versions.length - 1] ?? "v1";
    this.customFile = this.files.some(file => file.tcFilePath == this.currentfile);
    // validate if current country is not default
    if(this.currentCountry != "default"){
      this.currentCountry = this.countries.find(country => country == this.currentCountry) ?? "default";
    }
    else{
      this.currentCountry = "default";
    }

    this.pdfSource = this.pdfPath;
    this.acceptanceList = [... new Set(this.files.filter(file => file.version == this.currentVersion && file.fileName == this.currentfile).map(file => file.acceptance)
      .filter(accept => accept != null))];
  }

  changeFilePath(path: string) {
    this.currentfile = path?.split("/")?.pop() ?? "";
    this.versions = [... new Set(this.filePaths.filter(path => path.includes(this.currentfile)).map(path => path.split("/")[2]).slice())];
    
  
    this.currentVersion = this.versions[this.versions.length - 1] ?? path.split("/")[2] ?? "v1";
    // validate if current country is not default
    if(this.currentCountry != "default"){
      this.currentCountry = this.countries.find(country => country == this.currentCountry) ?? "default";
    }
    else{
      this.currentCountry = "default";
    }
    this.pdfSource = this.pdfPath;
    this.acceptanceList = [... new Set(this.files.filter(file => file.version == this.currentVersion && file.fileName == this.currentfile).map(file => file.acceptance)
      .filter(accept => accept != null))];
  }

  changeVersion(version: string) {
    this.currentVersion = version;
    this.pdfSource = this.pdfPath;
    this.acceptanceList = [... new Set(this.files.filter(file => file.version == this.currentVersion && file.fileName == this.currentfile).map(file => file.acceptance)
      .filter(accept => accept != null))];
  }

  changeCountry(country: string) {
    this.currentCountry = country.toLowerCase() == "default" ? "default" : country;
    this.pdfSource = this.pdfPath;
    this.acceptanceList = [... new Set(this.files.filter(file => file.countryCode == this.currentCountry && file.version == this.currentVersion && file.fileName == this.currentfile).map(file => file.acceptance)
      .filter(accept => accept != null))];
    this.currentVersion = "v1"
  }

  checkCookieFile() {
    var presetFile = this.cookieService.get('GSMSelectedSubscription');
    if (presetFile != null && presetFile != "") {
      this.currentStripeAccount = presetFile?.split("/")[0];
      this.currentfilePath = presetFile
      //console.log("presetFile = " + presetFile);
      this.changeFilePath(presetFile)
    }
  }

  checkSubscriptions() {
    var urlFile = this.route.snapshot.paramMap.get('file');
    if (urlFile != null && urlFile != "") {
      this.currentStripeAccount = urlFile?.split("/")[0];
      this.changeFilePath(urlFile);
    }

    this.fileService.getTermsAndConditions().subscribe(tcFiles => {
      this.files = tcFiles;
      var pendingSubIndex = tcFiles.findIndex(file => file.pendingAcceptance);
      this.filePaths = [... new Set(tcFiles.map(file => file.fullPath))];
      this.selectablefiles = [... new Set(tcFiles.map(file => file.fileName ?? ""))];
      this.currentStripeAccount = this.currentStripeAccount == "" ? tcFiles[0].stripeAccount : this.currentStripeAccount;
      if (pendingSubIndex != -1 && this.currentfile == "") {
        var currentFile = tcFiles[pendingSubIndex]?.fileName ?? "";
        this.changeFilePath(currentFile);
      }
      if (tcFiles.length == 1){
        var onlyFile = tcFiles[0].fileName;
        this.changeFile(onlyFile);
      }
      this.loadingDialog.closeAll();
    });

  }

  // get terms and conditions by language
  getTermsAndConditionsByLanguage(language: string): void {
    this.fileService.getTermsAndConditionsByLanguage(language).subscribe({
      next: files => {
        this.countries = [... new Set(files.map(file => file.countryCode))];
        this.countries.sort((a, b) => a.length - b.length);
        // add default country at the end of the list
        this.countries.unshift("Default");
      }
    });
  }

}
