import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, map, Observable, of, retry, tap, throwError } from 'rxjs';
import { StripeAccount } from '../interfaces/stripe-account.model';

@Injectable({
  providedIn: 'root'
})
export class StripeAccountService {
  private _baseUrl = environment.apiBaseURL + "Admin/Account";

  constructor(private http: HttpClient) { }

  get(): Observable<StripeAccount[]> {
    return this.http.get<StripeAccount[]>(this._baseUrl+"/UserStripeAccounts", {withCredentials: true}).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

}
