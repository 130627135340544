import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { StripeAccount } from '../../interfaces/stripe-account.model';

@Component({
  selector: 'app-stripe-account-selector',
  templateUrl: './stripe-account-selector.component.html',
  styleUrls: ['./stripe-account-selector.component.scss']
})
export class StripeAccountSelectorComponent implements OnInit {
  @Input('activeAccount') activeAccount ="";
  @Input('availableAccounts') availableAccounts: StripeAccount[] = [];
  @Output() selectedStripeAccount = new EventEmitter<string>();
  constructor() { }

  ngOnInit() {
  }

  change(stripeAccount: string) {
    this.selectedStripeAccount.emit(stripeAccount);
  }

}
