<app-toolbar [pageName]="t('upcoming_payments_page-title')" *transloco="let t"></app-toolbar>
<div class="main">
    <div class="table-div">
        <table mat-table [dataSource]="paymentRecords" class="mat-elevation-z8 recordsTable" 
            matSort (matSortChange)="sortPayments($event)">
            <ng-containter matColumnDef="customerId">
                <th mat-header-cell *matHeaderCellDef><span mat-sort-header="customerId" *transloco="let t">{{ t('upcoming_payments_page-customer_id_num') }}</span></th>
                <td mat-cell *matCellDef="let record" class="recordsCell">
                    {{ record.payment.subscription.customerIdCrm }}
                </td>
            </ng-containter>
    
            <ng-containter matColumnDef="customerName">
                <th mat-header-cell *matHeaderCellDef><span mat-sort-header="customerName" *transloco="let t">{{ t('upcoming_payments_page-company_name') }}</span></th>
                <td mat-cell *matCellDef="let record" class="recordsCell">
                    {{ record.payment.subscription.customerName }}
                </td>
            </ng-containter>
    
            <ng-containter matColumnDef="subscriptionDescription">
                <th mat-header-cell *matHeaderCellDef><span mat-sort-header="subscriptionDescription" *transloco="let t">{{ t('upcoming_payments_page-subscription') }}</span></th>
                <td mat-cell *matCellDef="let record" class="recordsCell">
                    {{ record.payment.subscription.description }}
                </td>
            </ng-containter>

            <ng-containter matColumnDef="paymentDate">
                <th mat-header-cell *matHeaderCellDef><span mat-sort-header="paymentDate" *transloco="let t">{{ t('upcoming_payments_page-paydate') }}</span></th>
                <td mat-cell *matCellDef="let record" class="recordsCell">
                    {{record.payment.payDate | date:'fullDate'}} 
                </td>
            </ng-containter>

            <ng-containter matColumnDef="subscriptionDefaultPayment">
                <th mat-header-cell *matHeaderCellDef><span mat-sort-header="subscriptionDefaultPayment" *transloco="let t">{{ t('upcoming_payments_page-pay_method') }}</span></th>
                <td mat-cell *matCellDef="let record" class="recordsCell">
                    <div *ngIf="record.expandPopulated; else revealButton">
                        {{ record.payment.methodDescription }}
                    </div>
                    <ng-template #revealButton>
                        
                        <button mat-raised-button *transloco="let t" (click)="populatePaymentDetails(record)">
                            {{ t('upcoming_payments_page-reveal') }}
                            <mat-progress-bar mode="indeterminate" *ngIf="record.expandDetails"></mat-progress-bar>
                        </button>
                    </ng-template>
                    
                </td>
            </ng-containter>

            <ng-containter matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef><span mat-sort-header="amount" *transloco="let t">{{ t('upcoming_payments_page-amount') }}</span></th>
                <td mat-cell *matCellDef="let record" class="recordsCell">
                    <div *ngIf="record.expandPopulated">
                        {{getCurrencySymbols(record.payment)}} {{record.payment.totalAmount | number:'1.2-2'}}
                    </div>
                    <!-- <ng-template #revealButton>
                        
                        <button mat-raised-button *transloco="let t" (click)="populatePaymentDetails(record)">
                            {{ t('upcoming_payments_page-reveal') }}
                            <mat-progress-bar mode="indeterminate" *ngIf="record.expandDetails"></mat-progress-bar>
                        </button>
                    </ng-template> -->
                    <!--  -->
                </td>
            </ng-containter>

            <!-- <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                <td mat-cell *matCellDef="let record">
                    <button mat-icon-button aria-label="expand row" (click)="populatePaymentDetails(record);">
                        <mat-icon *ngIf="!record.expandDetails">keyboard_arrow_down</mat-icon>
                        <mat-icon *ngIf="record.expandDetails">keyboard_arrow_up</mat-icon>
                    </button>
                </td>
            </ng-container>     -->
            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="recordsHeader"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        <div *ngIf="showLoading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
    </div>
</div>