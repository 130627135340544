import { Pipe, PipeTransform } from '@angular/core';
import { Price } from '../interfaces/price.model';

@Pipe({
  name: 'priceDisplay'
})
export class PriceDisplayPipe implements PipeTransform {

  transform(price: Price): string {
    return " " + price.amountDecimal + "/" + price.schedule.interval;
  }

}
