<div *ngIf="loading && state != 8" >
    <mat-progress-bar mode="indeterminate" value="40"></mat-progress-bar>
</div>
<div class="logoutDiv"> 
    <button mat-button color="basic">
        <mat-icon class="toolbar-icon" (click) = 'logout()'>
            exit_to_app
        </mat-icon>
    </button>
</div>
<div *ngIf="!loading" >
    <div *ngIf="state == 1">
        <app-customer-association></app-customer-association>
    </div>
    <div *ngIf="state == 4">
        <app-tax-validation></app-tax-validation>
    </div>
    <div *ngIf="state ==6">
        <app-payment-method></app-payment-method>
    </div>
    <div *ngIf="state ==7">
        <app-accept-terms-and-conditions></app-accept-terms-and-conditions>
    </div>
</div>
<div>
    <div *ngIf="state==8" class="completeDialog">
        <h1 mat-dialog-title *transloco="let t" >{{ t('home_page-login_check-complete_title') }}</h1>
        <div mat-dialog-content>
        </div>
        <div mat-dialog-actions class="completeDialogButton">
            <button mat-raised-button color="primary" *transloco="let t" (click)="completeSetup()">
                {{ t('home_page-login_check-complete')}}
            </button>
        </div>
    </div>
</div>
