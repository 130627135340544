import {Language} from '../interfaces/language.model';

export const AVAILABLE_LANGUAGES : Language[] = [
    { id: 'cs', label: 'Czech' },
    { id: 'da', label: 'Danish' }, 
    { id: 'de', label: 'German'}, 
    { id: 'en', label: 'English'}, 
    { id: 'es', label: 'Spanish'},
    { id: 'es-419', label: 'Spanish_Latin_America'},
    { id: 'el', label: 'Greek'},
    { id: 'et', label: 'Estonian' },
    { id: 'fi', label: 'Finnish'}, 
    { id: 'fr', label: 'French' },
    { id: 'fr-CA', label: 'French_Canadian' },
    { id: 'hu', label: 'Hungarian' },
    { id: 'it', label: 'Italian' },
    { id: 'lt', label: 'Lithuanian' },
    { id: 'lv', label: 'Latvian' },
    { id: 'mt', label: 'Maltese' },
    { id: 'pl', label: 'Polish'},   
    { id: 'pt', label: 'Portuguese'}, 
    { id: 'nl', label: 'Dutch'},
    { id: 'nb', label: 'Norwegian'},
    { id: 'sv', label: 'Swedish' }]
