import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { ActivateSubscriptionPageComponent } from './pages/activate-subscription-page/activate-subscription-page.component';
// import { CustomerDashboardPageComponent } from './pages/customer-dashboard-page/customer-dashboard-page.component';
import { IncompleteSubscriptionsPageComponent } from './pages/incomplete-subscriptions-page/incomplete-subscriptions-page/incomplete-subscriptions-page.component';
import { NewSubscriptionPageComponent } from './pages/new-subscription-page/new-subscription-page/new-subscription-page.component';
import { PageNotFoundComponent } from './pages/error-pages/page-not-found/page-not-found.component';
import { MsalGuard } from '@azure/msal-angular';
import { PageErrorComponent } from './pages/error-pages/page-error/page-error.component';
import { UpcomingPaymentsPageComponent } from './pages/upcoming-payments-page/upcoming-payments-page/upcoming-payments-page.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { IsExternal } from './shared/user/interfaces/is-external';
import  { IsInternal } from './shared/user/interfaces/is-internal';
import { SubscriptionsDashboardPageComponent } from './pages/subscriptions-dashboard-page/subscriptions-dashboard-page.component';
import { CheckoutPageComponent } from './pages/checkout-page/checkout-page.component';
import { AccountsPageComponent } from './pages/accounts-page/accounts-page.component';
import { AccountPageComponent } from './pages/accounts-page/account-page/account-page.component';


const routes: Routes = [
  {
    path: 'home', 
    component: HomePageComponent,
    canActivate: [MsalGuard], 
    title: "GSM: Home"
  },
  // {
  //   path: 'crm/dashboard', 
  //   component: CustomerDashboardPageComponent,
  //   canActivate: [MsalGuard], 
  //   title: "GSM: Dashboard"
  // },
    {
      path: 'subscription/dashboard', 
      component: SubscriptionsDashboardPageComponent,
      canActivate: [MsalGuard], 
      title: "GSM: Dashboard"},
    {
      path: 'payments', 
      component: UpcomingPaymentsPageComponent,
      canActivate: [MsalGuard, IsExternal], 
      title: "GSM: UpcomingPayments"},
  // {
  //   path: 'incomplete-subscriptions', 
  //   component: IncompleteSubscriptionsPageComponent,
  //   canActivate: [MsalGuard], 
  //   title: "GSM: Incomplete Subscriptions"},
  {
    path: 'new-subscription', 
    component: NewSubscriptionPageComponent,
    canActivate: [MsalGuard, IsInternal], 
    title: "GSM: New Subscriptions"},
  {
    path: 'activate-subscription/:id', 
    component: ActivateSubscriptionPageComponent,
    canActivate: [MsalGuard], 
    title: "GSM: Activate Subscription"
  },
  {
    path:'terms-conditions',
    component: TermsAndConditionsComponent,
    title: "Terms and Conditions"
  },
  {
    path:'checkout/:id',
    canActivate: [MsalGuard], 
    component: CheckoutPageComponent,
    title: "Fetching Checkout"
  },
  {
    path:'accounts',
    canActivate: [MsalGuard],
    component: AccountsPageComponent,
    title: 'Accounts'
  },
  {
    path:'account/:id',
    canActivate: [MsalGuard],
    component: AccountPageComponent,
    title: 'Account Details'
  },
  {
    path: '', 
    redirectTo: '/home', 
    pathMatch: 'full'
  },
  {
    path: '404',
    component: PageNotFoundComponent
  },
  {
    path: 'error',
    component: PageErrorComponent
  },
  {
    path: '**', 
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [IsExternal, IsInternal]
})
export class AppRoutingModule { }
