import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Language } from '../../interfaces/language.model';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {
  @Input('activeLang') activeLang ="";
  @Input('availableLangs') availableLangs: Language[] = [];
  @Output() selectedLang = new EventEmitter<string>();
  constructor() { }

  ngOnInit(): void {
  } 

  change(lang: string) {
    this.selectedLang.emit(lang);
  }

}
