export enum LoginState{
    Initial,
    AssociateCustomer,
    GetStripeAccounts,
    GetCustomers,
    ValidateTax,
    GetPaymentCheckouts,
    ValidatePayment,
    ValidateAcceptTermsAndConditions,
    Complete
}