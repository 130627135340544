import { Injectable } from '@angular/core';
import { catchError, Observable, retry, throwError } from 'rxjs';

import { Customer } from '../interfaces/customer.model';

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CustomerVerification } from '../interfaces/verification.model';
import { DistributionRegionSite } from '../../payment/interfaces/distribution-region-site.model';
import { CustomerDropdown } from '../../payment/interfaces/customer-dropdowns.model';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  //private customerUrl = 'api/customers/';
  private _baseUrl = environment.apiBaseURL + "CRM/Customer";
  constructor(private http: HttpClient) { }

  getCustomersForUser(searchFilter: string = ""): Observable<Customer[]> {
    return this.http.get<Customer[]>(this._baseUrl + "/ListForUser?searchFilter=" + searchFilter, { withCredentials: true }).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  getCustomers(searchFilter: string = ""): Observable<Customer[]> {
    return this.http.get<Customer[]>(this._baseUrl + "/List?searchFilter=" + searchFilter, { withCredentials: true }).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  getCustomersByIds(ids: string[]): Observable<Customer[]> {
    return this.http.post<Customer[]>(this._baseUrl + "/ListByIds", ids, { withCredentials: true }).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  getCustomer(id: string): Observable<Customer> {
    return this.http.get<Customer>(this._baseUrl + "/Get?id=" + id, { withCredentials: true }).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  verifyCustomer(verification: CustomerVerification) {
    return this.http.post<boolean>(this._baseUrl + "/Verify", verification, { withCredentials: true }).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  populateCustomerBillingIds(customer: Customer): Observable<Customer> {
    return this.http.post<Customer>(this._baseUrl + "/GetBillingIds", customer, { withCredentials: true }).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  getDistributors(crmId: string = ""): Observable<Customer[]> {
    return this.http.post<Customer[]>(this._baseUrl + "/GetCustomerDistributor?crmid=" + crmId, { withCredentials: true }).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  getMSODependancies(crmId: string = ""): Observable<Customer[]> {
    return this.http.post<Customer[]>(this._baseUrl + "/GetCustomerMSODependants?crmid=" + crmId, { withCredentials: true }).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  getImporters(crmId: string = "", productId: string): Observable<Customer[]> {
    return this.http.post<Customer[]>(this._baseUrl + "/GetImporters?crmid=" + crmId + "&productId=" + productId, { withCredentials: true }).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }  

  getCustomerDropdownValues(customerDropdown: CustomerDropdown): Observable<DistributionRegionSite[]> {
    return this.http.post<DistributionRegionSite[]>(this._baseUrl+"/GetCustomerDropdown",customerDropdown,{withCredentials: true}).pipe(
      catchError((error:HttpErrorResponse)=>{
        console.error(error);
        return throwError(() => error);
      })
    );
  }
}
