
<h1 mat-dialog-title *transloco="let t" >{{ t('home_page-customer_association-title') }}</h1>
<div mat-dialog-content>
    <!-- <div *ngIf="validated">
        <span class="successMessage" *transloco="let t"> {{t('general_message_success')}}</span>
    </div> -->
    <mat-form-field appearance="standard"  class="formField">
        <mat-label *transloco="let t">{{ t('home_page-customer_association-customer_id') }}</mat-label>
        <input mat-input type="text" type="number" onkeypress="return event.charCode >= 48 && event.charCode <= 57"
        matInput 
        [formControl]="customerIdControl">
        <mat-error *ngIf="customerIdControl.hasError('required')">
            <span *transloco="let t">{{ t('home_page-customer_association-select_valid_customer_id') }}</span>
            </mat-error>
    </mat-form-field>
    <!-- <mat-form-field appearance="standard"  class="formField">
        <mat-label *transloco="let t">{{ t('home_page-customer_association-location-country') }}</mat-label>
        <mat-select [formControl]="countryControl">
            <mat-option *ngFor="let country of countrySelection" [value]="country">
                <span *transloco="let t">{{t('general-country_'+country)}}</span>
            </mat-option>
        </mat-select>
        <mat-error *ngIf="countryControl.hasError('required')">
            <span *transloco="let t">{{ t('home_page-customer_association-select_country') }}</span>
            </mat-error>
    </mat-form-field> -->
    <mat-form-field appearance="standard"  class="formField">
        <mat-label *transloco="let t">{{ t('home_page-customer_association-location-postal') }}</mat-label>
        <input mat-input type="text" 
        matInput 
        [formControl]="postalCodeControl">
        <mat-error *ngIf="postalCodeControl.hasError('required')">
            <span *transloco="let t">{{ t('home_page-customer_association-select_postal_code') }}</span>
        </mat-error>
    </mat-form-field>
    <div *ngIf="validationErrorMessage">
        <span class="errorMessage" *transloco="let t"> {{t('home_page-customer_association-validate_error')}}</span>
    </div>
    <div *ngIf="validationUnexpectedErrorMessage">
        <span class="errorMessage" *transloco="let t"> {{t('home_page-customer_association-validate_unexpected_error')}}</span>
    </div>
</div>
<div mat-dialog-actions>
    <mat-progress-bar mode="indeterminate" value="40" *ngIf="waiting"></mat-progress-bar>
    <div>
        <button mat-button *transloco="let t" [disabled]="!customerAssociationForm.valid || waiting" (click)="validateClick()">
            {{ t('home_page-customer_association-validate') }}
        </button>
        <button mat-button [disabled]="!validated || waiting" *transloco="let t" (click)="submitClick()">
            {{ t('home_page-customer_association-submit')}}
        </button>
    </div>
</div>

