import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-page-error',
  templateUrl: './page-error.component.html',
  styleUrls: ['./page-error.component.scss']
})
export class PageErrorComponent implements OnInit {
  headerMessage: string = "500 SERVER ERROR";
  errorMessage: string = '';
  showErrorDetails: boolean = false;
  constructor(private route: ActivatedRoute, private errorHandler: ErrorHandlerService) { }
  ngOnInit(): void {
    this.errorMessage = this.errorHandler.errorMessage;
    this.showErrorDetails = !environment.production;
  }
}