import { HttpErrorResponse } from '@angular/common/http';
import { Component,Inject,OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerService } from 'src/app/shared/payment/services/customer.service';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import {CustomerDashboardRecord} from '../interfaces/customer-dashboard-record.model';

@Component({
  selector: 'app-customer-dashboard-details-page',
  templateUrl: './customer-dashboard-details-page.component.html',
  styleUrls: ['./customer-dashboard-details-page.component.scss']
})
export class CustomerDashboardDetailsPageComponent implements OnInit {
  displayedColumns: string[] = ['customerId', 'name', 'address', 'subscriptionDescription', 'paymentDate', 'contact'];
  billingPortalUrl: string = "";

  constructor(public dialogRef: MatDialogRef<CustomerDashboardDetailsPageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CustomerDashboardRecord, 
    private customerService: CustomerService,
    private errorHandler: ErrorHandlerService) { }

  ngOnInit(): void {
    this.showExtraInformation(true);
    this.getBillingPortalUrl();
  }

  showExtraInformation(loadTableInfo: boolean): Boolean {
    const showExtraInformation = this.data.subscriptions.some(cond => cond.paidForCustomerId != null && cond.paidForCustomerId.length > 0);

    if (showExtraInformation) {
      if (loadTableInfo)
        this.displayedColumns = ['customerId', 'name', 'address', 'subscriptionDescription', 'paymentDate', 'contact'];
    } else {
      if (loadTableInfo)
        this.displayedColumns = ['subscriptionDescription', 'paymentDate', 'contact'];
    }
    
    return showExtraInformation;
  }

  getBillingPortalUrl(): void{
    if (this.data.subscriptions.length > 0) {
      let customerIdToGetLink = (this.showExtraInformation(false) ? this.data.subscriptions[0].paidByCustomerId : this.data.subscriptions[0].customerId);
    
      this.customerService.GetBillingPortalLink(customerIdToGetLink).subscribe({
        next: url => {
          this.billingPortalUrl = url;
        },
        error: (err: HttpErrorResponse) => {
          this.errorHandler.handleError(err);
        }
      })
    }
  }

  manageAccountClick(): void{
    if(this.data.subscriptions.length > 0)
    {
      window.open(this.billingPortalUrl, "_blank");
    } 
  }
}
