import { Component } from '@angular/core';
import { AuthenticationService } from './shared/authentication/authentication.service';
import { LanguageService } from './shared/language/services/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private authenticationService: AuthenticationService,
    private languageService: LanguageService){}

  ngOnInit() {
    this.authenticationService.initialize();
    this.languageService.initialize();
}
}
