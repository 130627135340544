import { HttpErrorResponse } from '@angular/common/http';
import { Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { Customer } from 'src/app/shared/payment/interfaces/customer.model';
import { CustomerService } from 'src/app/shared/payment/services/customer.service';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import { LoginFlowService } from '../login-flow.service';
import { SubscriptionCheckout } from '../../payment/interfaces/subscription-checkout.model';
import { CookieService, SameSite } from 'ngx-cookie-service';
import { filter } from 'rxjs';
import { getCurrencySymbol } from '@angular/common';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit {
loading: boolean = true;
displayedColumns: string[] = ['customerId', 'customerName', 'subscriptionDescription', 'price', 'url'];
customer: Customer | null = null;

checkouts: SubscriptionCheckout[] = [];

  constructor(private customerService: CustomerService,
    private errorHandler: ErrorHandlerService,
    private loginFlowService: LoginFlowService,
    private cookieService: CookieService) { }

  ngOnInit(): void {
    this.getPendingCheckouts();
  }

  getPendingCheckouts(): void{
    this.loginFlowService.getPendingCheckouts().subscribe({
      next: checkouts=> {
        var stripeCheckouts = checkouts.filter(checkout=> checkout.acceptInStripe);
        this.loading = false;

        stripeCheckouts.forEach((checkout) => {
          if (checkout.crmParentId != null && checkout.crmParentId != '' && parseInt(checkout.price.amountDecimal) == 0) {
            this.displayedColumns = ['customerId', 'customerName', 'parent', 'subscriptionDescription', 'url']
          }
          else if (checkout.crmParentId != null && checkout.crmParentId != '') {
            this.displayedColumns = ['customerId', 'customerName', 'parent', 'subscriptionDescription', 'price', 'url']
          }
        });
        this.checkouts = stripeCheckouts;
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });
  }

  // getCustomer(): void{
  //   this.loginFlowService.getCustomerAssignments().subscribe({
  //     next: customers=> {
  //       this.loading = false;
  //       var customerRequiringSetup = customers.find(customer=> !customer.completedSetup);
  //       if (customerRequiringSetup != null){
  //         this.customer = customerRequiringSetup;
  //       }
  //     },
  //     error: (err: HttpErrorResponse) => {
  //       this.errorHandler.handleError(err);
  //     }
  //   })
  // }

  continueClick(url: string, termsAndConditionsPDFName: string): void{
    if(termsAndConditionsPDFName!=null){
      var sameSite: SameSite = 'Strict';
      this.cookieService.set('GSMSelectedSubscription', termsAndConditionsPDFName, 1, undefined, undefined, undefined, sameSite);
    }
     window.open(url, "_blank");
  }

  refreshClick(): void{
    this.loading = true;
    this.loginFlowService.refreshSubscriptionCheckouts();
  }

  public getCurrencySymbols(checkout: SubscriptionCheckout): string{
    var symbol
    symbol = getCurrencySymbol(checkout.price.currency,'narrow',undefined)
    return symbol
  }
}
