import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/shared/user/interfaces/user.model';
import { SubscriptionService } from 'src/app/shared/subscription/services/subscription.service';
import { AddressDisplayPipe } from 'src/app/shared/customer/pipes/address-display.pipe';
import { MatDialog } from '@angular/material/dialog';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import { LoginFlowService } from 'src/app/shared/login-check/login-flow.service';
import { ContactService } from 'src/app/shared/customer/services/contact.service';
import { CustomerService } from 'src/app/shared/payment/services/customer.service';
import { DatePipe } from '@angular/common';
import { Subject, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { SubscriptionDashboardRecord } from '../../subscriptions-dashboard-page/interfaces/subscription-dashboard-record.module';
import { SubscriptionsDashboardDetailsPageComponent } from '../../subscriptions-dashboard-page/subscriptions-dashboard-details-page/subscriptions-dashboard-details-page/subscriptions-dashboard-details-page.component';
import { PageEvent } from '@angular/material/paginator';
import { SubscriptionSummary } from 'src/app/shared/payment/interfaces/subscription-summary.model';
import { CustomerSummary } from 'src/app/shared/payment/interfaces/customer-summary.model';

@Component({
  selector: 'app-account-page',
  templateUrl: './account-page.component.html',
  styleUrls: ['./account-page.component.scss']
})
export class AccountPageComponent implements OnInit {
  customerId: string = "";
  customer!: CustomerSummary;
  records: SubscriptionSummary[] = [];
  displayedPage: SubscriptionSummary[] = [];
  pageSize: number = 5;
  pageIndex: number = 0;
  reachedEnd: boolean = false;
  displayedColumns: string[] = ['customerId', 'customerName', 'customerCountry', 'customerBillingAddress', 'customerShippingAddress', 'subscriptionStatus', "erpOrderNumber", 'customerPaymentEntered', 'subscriptionDescription', 'subscriptionContact', 'subscriptionSubmittedBy', 'subscriptionCreated'];
  //displayedExpandedColumns: string[] = [];//'subscriptionId', 'subscriptionCreated', 'subscriptionDescription', 'subscriptionStatus', 'subscriptionContact', 'hardwareShipped'];
  showLoading: boolean = false;
  currentUser: User | null = null;
  datePipe!: DatePipe;
  distributorView: boolean = true;
  billingPortalUrl = '';
  private readonly _destroying$ = new Subject<void>();

  constructor(private route: ActivatedRoute,
    private subscriptionService: SubscriptionService,
    private addressDisplayPipe: AddressDisplayPipe,
    private detailsDialog: MatDialog,
    private errorHandler: ErrorHandlerService,
    private loginFlowService: LoginFlowService,
    private customerService: CustomerService,
    private contactService: ContactService) { }

  ngOnInit(): void {
    this.customerId = this.route.snapshot.params["id"];
    this.datePipe = new DatePipe('en-US');
    this.checkLogin();
    this.getCustomerRecords();
    
  }

  checkLogin(): void{
    this.loginFlowService.getUser().subscribe({
      next: user=>{
        this.currentUser = user;
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });    
  }

  getCustomerRecords(){
    this.showLoading = true;
    this.subscriptionService.getSubscriptionSummariesForCustomer(this.customerId)
      .pipe(takeUntil(this._destroying$)).subscribe({
        next: customers=> {
          if(customers.length>0){
            this.customer = customers[0];
            this.customer.subscriptions.forEach(subscription=> {
              if (!this.distributorView && subscription.crmParentId != null && subscription.crmParentId != '') {
                  this.distributorView = false;
                  this.displayedColumns = ['customerId', 'customerName', 'customerCountry', 'customerBillingAddress', 'customerShippingAddress', 'subscriptionStatus', 'erpOrderNumber', 'customerPaymentEntered', 'parentId', 'parentName', 'subscriptionDescription', 'subscriptionContact', 'subscriptionSubmittedBy', 'subscriptionCreated'];
                }
  
                this.records.push(subscription);
              });
          }
          else this.records = [];
          this.showLoading = false;
          this.getCurrentPage();
          this.getBillingPortalUrl();
        },
        error: (err: HttpErrorResponse) => {
          this.errorHandler.handleError(err);
        }
    });
  }

  handlePageEvent(e: PageEvent) {
    if (this.pageSize != e.pageSize) {
      this.pageIndex = 0;
      this.pageSize = e.pageSize;
    } else {
      this.pageIndex = e.pageIndex;
    }
    this.getCurrentPage();

  }

  getCurrentPage(): void {
    var startIndex = this.pageIndex * this.pageSize;
    var endIndex = this.pageIndex * this.pageSize + this.pageSize;
    this.displayedPage = this.records.slice(startIndex, endIndex > this.records.length ? this.records.length : endIndex);
  }

  openSubscriptionDetailsDialog(row: SubscriptionSummary): void{
    var custId = (row.paidBy == 5 ? row.crmParentId : this.customer.crmId);
    this.showLoading = true;
    var recordData: SubscriptionDashboardRecord = {
      customer: this.customer,
      subscription: row
    };

    this.contactService.getContacts(custId).subscribe({
      next: contacts=> {
        row.customerContact = contacts
        if (row.crmParentId != null)
        {
          this.contactService.getContacts(row.crmParentId).subscribe({
            next: contacts=> {
              row.payerContact = contacts;
              
              const dialogRef = this.detailsDialog.open(SubscriptionsDashboardDetailsPageComponent,
                {
                  width: '100vw',
                  height: '35vw',
                  data: recordData
                 });
                this.showLoading = false;
            },
            error: (err: HttpErrorResponse) => {
              this.showLoading = false;
              this.errorHandler.handleError(err);
            }
          }); 
        }
        else
        {
          const dialogRef = this.detailsDialog.open(SubscriptionsDashboardDetailsPageComponent,
            {
              width: '100vw',
              height: '35vw',
              data: recordData
             });
            this.showLoading = false;
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    }); 
  }

  getBillingPortalUrl(): void{
    if (this.records.length > 0) {
      this.customerService.GetBillingPortalLink(this.customer.id).subscribe({
        next: url => {
          this.billingPortalUrl = url;
        },
        error: (err: HttpErrorResponse) => {
          this.errorHandler.handleError(err);
        }
      })
    }
  }

  manageAccountClick(): void{
    if(this.records.length > 0)
    {
      window.open(this.billingPortalUrl, "_blank");
    } 
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

}
