import { Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalCustomNavigationClient } from '@azure/msal-angular';
import { EventMessage, InteractionStatus, EventType, RedirectRequest, AuthError } from '@azure/msal-browser';
import { filter, merge, skipWhile, Subject, switchMap, takeUntil } from 'rxjs';
import { LoginCheckComponent } from '../login-check/login-check.component';
import { LoginFlowService } from '../login-check/login-flow.service';
import { MatFooterCell } from '@angular/material/table';
import { LoadingComponent } from '../navigation/components/loading/loading.component';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private readonly _destroying$ = new Subject<void>();
  private loginDialogOpened: Boolean = false;
  private loadingDialogOpened: Boolean = false;
  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private broadcastService: MsalBroadcastService,
    private authService: MsalService,
    private loginFlowService: LoginFlowService,
    private loginDialog: MatDialog,
    private loadingDialog: MatDialog,
    private router: Router) { }

  ngOnInit(): void{
    
  }

  initialize() {
    // this.broadcastService.inProgress$
    // .pipe(
    //   filter((status: InteractionStatus) => status === InteractionStatus.HandleRedirect),
    //   takeUntil(this._destroying$)
    // )
    // .subscribe((x) => {
    //   console.log("redirect");
    //   alert("redirect");
    // });
    var loadingdialog: MatDialogRef<LoadingComponent, any>;
    var event = this.broadcastService.msalSubject$;
    event.pipe(
      filter((msg: EventMessage)=> msg.eventType == EventType.LOGIN_FAILURE),
      takeUntil(this._destroying$)
    ).subscribe((result: EventMessage)=> {
      if (result.error instanceof AuthError) {
        this.authService.loginRedirect();
      }
    });  
    
    this.loginFlowService.waitingUserInput.pipe(filter(inputRequired=> inputRequired)).subscribe(() => {
      this.openLoginDialog();
    });

    this.loginFlowService.isCalculatingState()
    .subscribe(calculating=>{
      if(calculating && !this.loadingDialogOpened && !this.loginDialogOpened 
          && this.pageRequiresLoginFlow()){
        this.loadingDialogOpened = true;
        loadingdialog = this.loadingDialog.open(LoadingComponent,{
          closeOnNavigation: false,
          disableClose: true
        });
        loadingdialog.afterClosed().subscribe(()=> this.loadingDialogOpened = false);
      }else if(!calculating && this.loadingDialogOpened){
        this.loadingDialogOpened = false;
        loadingdialog.close();
      }
    }
    )

    this.router.events.pipe(filter((val)=> val instanceof NavigationEnd), skipWhile(()=>!this.pageRequiresLoginFlow()))
      .subscribe(()=>this.loginFlowService.calculateState());
  }

  // loginRedirect() {
  //   if (this.msalGuardConfig.authRequest){
  //     this.authService.loginRedirect({...this.msalGuardConfig.authRequest} as RedirectRequest);
  //   } else {
  //     this.authService.loginRedirect();
  //   }
  //}

  openLoginDialog(){
    if(!this.loginDialogOpened && this.pageRequiresLoginFlow()){
      this.loginDialogOpened = true;
      this.loginDialog.open(LoginCheckComponent,{
        width: '90vw',
        closeOnNavigation: false,
        disableClose: true
      }).afterClosed().subscribe(()=>{
        this.loginDialogOpened = false;
      })
    }
  }

  pageRequiresLoginFlow(): boolean{
    return !this.router.url.startsWith("/terms-conditions")
    && !this.router.url.startsWith("/checkout");
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

}
