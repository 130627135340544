import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, first } from 'rxjs';
import { Price } from 'src/app/shared/subscription/interfaces/price.model';
import { CurrencySymbolPipe } from 'src/app/shared/subscription/pipes/currency-symbol.pipe';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent implements OnInit {
  @Input() PriceOptions$: BehaviorSubject<Price[]> = new BehaviorSubject<Price[]>([]);
  @Input() startingDate: Date = new Date();
  @Output() scheduleSelectedEvent = new EventEmitter<Price[]>();
  scheduleSteps: Price[] = [];
  displayedColumns = ["description", "subtotal", "startDate", "endDate"];

  constructor(
    private currencySymbolPipe: CurrencySymbolPipe
  ) { }

  ngOnInit(): void {
    this.getSteps();
  }

  getSteps(): void {
    this.PriceOptions$.asObservable().subscribe(priceOptions => {
      this.scheduleSteps = [];
      var firstStep = priceOptions.filter(priceOption => priceOption.schedule.isStartingPrice)[0];

      if (firstStep == null) {
        firstStep = priceOptions.filter(priceOption => priceOption.default && priceOption.schedule.scheduleOptions.isStartingPriceOption)[0];
      }

      if (firstStep == null) {
        firstStep = priceOptions.filter(priceOption => priceOption.schedule.scheduleOptions.isStartingPriceOption)[0];
      }

      if (firstStep == null) {
        firstStep = priceOptions.filter(priceOption => priceOption.description === 'MSO Multiple Location Discount')[0];
      }

      if (firstStep != null) {
        firstStep = this.setPriceDates(firstStep, this.startingDate);
        var nextStartDate = firstStep.schedule.endDate;
        this.scheduleSteps.push(firstStep);
        var nextStep = priceOptions.find(priceOption => priceOption.id == firstStep.schedule.nextPrice);
        while (nextStep != undefined) {
          nextStep = this.setPriceDates(nextStep, nextStartDate);
          nextStartDate = nextStep.schedule.endDate;
          this.scheduleSteps.push(nextStep)
          nextStep = priceOptions.find(priceOption => priceOption.id == nextStep?.schedule?.nextPrice);
        }
      }

      this.scheduleSelectedEvent.emit(this.scheduleSteps);
    });

  }

  setPriceDates(step: Price, startDate: Date): Price {
    step.schedule.startDate = new Date(startDate);
    step.schedule.endDate = new Date(this.getEndDate(startDate, step.schedule.interval, step.schedule.intervalCount > 1? step.schedule.intervalCount: step.schedule.length));
    return step;
  }

  getEndDate(startDate: Date, interval: string, length: number): Date {
    var returnDate: Date = new Date(startDate);
    switch (interval.toLowerCase()) {
      case ("day"): {
        returnDate.setDate(returnDate.getDate() + length);
        break;
      }
      case ("month"): {
        returnDate.setMonth(returnDate.getMonth() + length);
        break;
      }
      case ("week"): {
        returnDate.setDate(returnDate.getDate() + (length * 7));
        break;
      }
      case ("year"): {
        returnDate.setMonth(returnDate.getMonth() + (length * 12));
        break;
      }

    }
    return returnDate;
  }

  getCurrencySymbols = (price: Price) => {
    return this.currencySymbolPipe.transform(price);
  }
}
