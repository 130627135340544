export class CustomerDropdown{
    customerId: string;
    payerCustomerCtsId: string | null;
    state: string;
    countryCode: string;

    /**
     *
     */
    constructor(customerId: string,payerCustomerCtsId: string | null,state: string,countryCode: string) {
        this.customerId = customerId;
        this.payerCustomerCtsId = payerCustomerCtsId;
        this.state = state;
        this.countryCode = countryCode;
        
    }
}