export const environment = {
  production: false,
  postLogoutRedirectUri: 'http://localhost:4200',
  clientId: '74528526-a466-4d51-985f-5c6dfd3f6b51',
  knownAuthorities: ["ppgindustriesincb2cdev.b2clogin.com"],
  authority: 'https://ppgindustriesincb2cdev.b2clogin.com/ppgindustriesincb2cdev.onmicrosoft.com/B2C_1_GSM_Dev_SignUpSignIn',
  readScope: 'https://ppgindustriesincb2cdev.onmicrosoft.com/gsm-refinish-api-dev/gsm.read',
  writeScope: 'https://ppgindustriesincb2cdev.onmicrosoft.com/gsm-refinish-api-dev/gsm.write',
  apiBaseURL: 'https://localhost:44321/api/',
  apiDomain: 'https://localhost:44321',
  tstBanner: true,
  termsAndConditionsUrl: 'https://strefgsmstrgpdev01.blob.core.windows.net/termsandconditions',
  customTermsAndConditionsUrl: 'https://strefgsmstrgpdev01.blob.core.windows.net/customtermsandconditions'
};