import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, retry, throwError } from 'rxjs';

import { Product } from '../interfaces/product.model';
import { ProductCategory } from "../interfaces/product-category.model";

import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Customer } from '../../customer/interfaces/customer.model';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  //private productUrl = 'api/products/'
  private _baseUrl = environment.apiBaseURL + "Payment/Product";

  private initialized: boolean = false;

  constructor(private http: HttpClient) {
  }

  getProducts(customer: Customer, loadUnchargedProducts: boolean = false): Observable<ProductCategory[]> {
    // if(!this.initialized){
    return this.http.post<ProductCategory[]>(this._baseUrl + "/Common/List?loadUnchargedProducts=" + loadUnchargedProducts, customer, { withCredentials: true }).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

}
