import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CustomerService } from 'src/app/shared/customer/services/customer.service';
import { CountryList } from 'src/app/shared/enums/country.list.enum';
import { CustomerVerification } from 'src/app/shared/customer/interfaces/verification.model';
import { User } from 'src/app/shared/user/interfaces/user.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from 'src/app/shared/user/services/user.service';
import { LoginFlowService } from '../login-flow.service';
import { UserAuthService } from 'src/app/shared/user/services/user-auth.service';
import { TranslocoService } from '@ngneat/transloco';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-customer-association',
  templateUrl: './customer-association.component.html',
  styleUrls: ['./customer-association.component.scss']
})
export class CustomerAssociationComponent implements OnInit {
  user!: User;
  customerIdControl = new FormControl<string>('', [Validators.required]);
  countryControl = new FormControl<string>('');
  postalCodeControl = new FormControl<string>('', [Validators.required]);
  countryList = CountryList;
  countrySelection: string[] = [];
  selectedCountry: string = "";
  waiting: boolean = false;
  validated: boolean = false;
  validationErrorMessage: boolean = false;
  validationUnexpectedErrorMessage: boolean = false;
  verification!: CustomerVerification;

  customerAssociationForm = new FormGroup({
    customerIdControl: this.customerIdControl,
    countryControl: this.countryControl,
    postalCodeControl: this.postalCodeControl
  });
  constructor(private customerService: CustomerService,
    private userService: UserService,
    private userAuthService: UserAuthService,
    private loginFlowService: LoginFlowService,
    private translocoService: TranslocoService,
    private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.getUser();
    this.buildCountrySelect();
    this.buildVerification();
    this.checkUserInput();
  }

  getUser(): void{
    this.loginFlowService.getUser().subscribe(user=> this.user = user);

  }

  checkUserInput(): void{
    this.loginFlowService.isUserInputRequired().subscribe(inputRequired=> this.waiting = !inputRequired);
  }

  buildCountrySelect(): void{
    this.countrySelection = Object.keys(this.countryList).filter(f => isNaN(Number(f)));
  }

  buildVerification(): void{
    this.customerAssociationForm.valueChanges.subscribe(form=> {
      this.verification = {
        customerId: form.customerIdControl ?? "",
        countrycode: form.countryControl ?? "",
        postalcode: form.postalCodeControl ?? "",
        userEmail: this.user.email
      }
    });
  }

  validateClick(): void{
    this.waiting = true;
    this.customerService.verifyCustomer(this.verification).subscribe({
      next: result=> {
      this.validationUnexpectedErrorMessage = false;
      this.validated = result;
      this.validationErrorMessage = !result;
      this.waiting = false;
      if(this.validated){
        this._snackBar.open(this.translocoService.translate('general_message_success'), this.translocoService.translate('general_message_dismiss'));
      }
      },
      error: (err: HttpErrorResponse) =>{
        this.validated = false;
        this.validationUnexpectedErrorMessage = true;
        this.waiting = false;
      }
    });
  }

  submitClick(): void{
    this.waiting = true;
    this.userService.addCustomerId(this.user.email, this.customerAssociationForm.value.customerIdControl ?? "").subscribe({
      next: ()=> {
        this.waiting = false;
        this._snackBar.open(this.translocoService.translate('customer_association-success') + this.customerIdControl.value, this.translocoService.translate('general_message_dismiss'));
        this.closeComponent();
      },
      error: (err: HttpErrorResponse) => this.unexpectedError()
    })
    
  }

  closeComponent(): void{
    this.loginFlowService.refreshUser();
  }

  unexpectedError(): void{
    this.validationUnexpectedErrorMessage = true;
    this.waiting = false;
  }

  /*
  if(this.customerAssociationForm.value.customerIdControl != null){
          this.paymentCustomerService.getCustomerByCrmId(this.customerAssociationForm.value.customerIdControl).subscribe({
            next: customer=> {
              this.loginFlowService.setCustomerAssignment(customer);
              
              this.waiting = false;
            },
            error: (err: HttpErrorResponse) => this.unexpectedError()
          });
        }else{
          this.unexpectedError();
        }
        */
}
