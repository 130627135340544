import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { Payment } from 'src/app/shared/payment/interfaces/payment.model';
import { PaymentService } from 'src/app/shared/payment/services/payment.service';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { CurrencySymbolPipe } from 'src/app/shared/subscription/pipes/currency-symbol.pipe';

@Component({
  selector: 'app-upcoming-payments-page',
  templateUrl: './upcoming-payments-page.component.html',
  styleUrls: ['./upcoming-payments-page.component.scss'],
})
export class UpcomingPaymentsPageComponent implements OnInit {
  displayedColumns: string[] = ['customerId', 'customerName', 'subscriptionDescription', 'paymentDate', 'subscriptionDefaultPayment'];
  paymentRecords: {id: number, payment: Payment, expandDetails: boolean, expandPopulated: boolean}[] = [];
  showLoading = true;
  constructor(private paymentService: PaymentService,
    private errorHandler: ErrorHandlerService,
    private currencySymbolPipe: CurrencySymbolPipe) { }

  ngOnInit(): void {
    this.populatePayments();
  }

  populatePayments(): void{
    var id = 0;
    this.paymentService.getPayments().subscribe({
      next: payments=> {
        var data: { id: number; payment: Payment; expandDetails: boolean; expandPopulated: boolean; }[] = [];
        payments.slice().forEach(payment=> {
          data.push({id, payment, expandDetails: false, expandPopulated: false});
          id += 1;
        });
        this.paymentRecords = data;
        this.showLoading = false;
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });
  }

  populatePaymentDetails(record: {id: number, payment: Payment, expandDetails: boolean; expandPopulated: boolean; }): void{
    this.displayedColumns = ['customerId', 'customerName', 'subscriptionDescription', 'paymentDate', 'subscriptionDefaultPayment', 'amount'];
    var data = this.paymentRecords.slice();
    record.expandDetails = true;
    data[record.id] = record;
    this.paymentService.getUpcomingDetails(record.payment).subscribe({
      next: resultPayment=> {
        //resultPayment.currency = resultPayment.currency.toUpperCase();
        data = this.paymentRecords.slice();
        data[record.id] = {id: record.id, payment: resultPayment, expandDetails: record.expandDetails,  expandPopulated: true };
        this.paymentRecords = data;
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });
  }

  sortPayments(sort: Sort){
    const data = this.paymentRecords.slice();

    if(!sort.active || sort.direction === ''){
      this.paymentRecords = data;
      return;
    }

    this.paymentRecords = data.sort((a,b) => {
      const isAsc = sort.direction === 'asc';
      switch(sort.active){
        case "customerId":
          return this._compare(a.payment.subscription.customerIdCRM, b.payment.subscription.customerIdCRM, isAsc);
        case "customerName":
          return this._compare(a.payment.subscription.customerName, a.payment.subscription.customerName, isAsc);
        case "subscriptionDescription":
          return this._compare(a.payment.subscription.description, b.payment.subscription.description, isAsc);
        case "paymentDate":
          return this._compare(a.payment.payDate, b.payment.payDate, isAsc);
        case "amount": 
          return this._compare(a.payment.totalAmount, b.payment.totalAmount, isAsc);
        default:
          return 0;
      }
    });
  }

  private _compare(a: number | string | Date, b: number | string | Date, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getCurrencySymbols = (payment: Payment) => {
    return this.currencySymbolPipe.transform(payment);
  }
}
