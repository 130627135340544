import { Pipe, PipeTransform } from '@angular/core';
import { Contact } from '../interfaces/contact.model';

@Pipe({
  name: 'contactBuild'
})
export class ContactBuildPipe implements PipeTransform {

  transform(firstName: string, lastName: string, email: string): Contact {
    return { id: undefined, firstName: firstName, lastName: lastName, email: email};
  }

}
