import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerSubscription } from 'src/app/shared/collections/customer-subscription.model';

@Component({
  selector: 'app-activate-subscription-dialog',
  templateUrl: './activate-subscription-dialog.component.html',
  styleUrls: ['./activate-subscription-dialog.component.scss']
})
export class ActivateSubscriptionDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ActivateSubscriptionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CustomerSubscription) { } 


  ngOnInit(): void {
  }

  cancelClick(): void{
    this.dialogRef.close();
  }
}
