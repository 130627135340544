import { DataSource } from '@angular/cdk/collections';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject, Observable, Subject, debounceTime, distinctUntilChanged, filter, map, mergeMap, skipWhile, takeUntil } from 'rxjs';
import { Customer } from 'src/app/shared/customer/interfaces/customer.model';
import { AddressDisplayPipe } from 'src/app/shared/customer/pipes/address-display.pipe';
import { CustomerService } from 'src/app/shared/customer/services/customer.service';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import { Subscription } from 'src/app/shared/subscription/interfaces/subscription.model';
import { SubscriptionService } from 'src/app/shared/subscription/services/subscription.service';
import { ContactService } from 'src/app/shared/customer/services/contact.service';
import { User } from 'src/app/shared/user/interfaces/user.model';
import { CustomerService as PaymentCustomerService } from 'src/app/shared/payment/services/customer.service';
import { Customer as PaymentCustomer } from 'src/app/shared/payment/interfaces/customer.model';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { CustomerSummary } from 'src/app/shared/payment/interfaces/customer-summary.model';
import { SubscriptionDashboardRecord } from './interfaces/subscription-dashboard-record.module';
import { DatePipe } from '@angular/common';
import { SubscriptionsDashboardDetailsPageComponent } from './subscriptions-dashboard-details-page/subscriptions-dashboard-details-page/subscriptions-dashboard-details-page.component';
import { LoginFlowService } from 'src/app/shared/login-check/login-flow.service';
import { SubscriptionsDashboardExportComponent } from './subscriptions-dashboard-export/subscriptions-dashboard-export.component';

@Component({
  selector: 'app-subscriptions-dashboard-page',
  templateUrl: './subscriptions-dashboard-page.component.html',
  styleUrls: ['./subscriptions-dashboard-page.component.scss']
})
export class SubscriptionsDashboardPageComponent implements OnInit {
  records: SubscriptionDashboardRecord[] = [];
  displayedRecords: SubscriptionDashboardRecord[] = [];
  displayedPage: SubscriptionDashboardRecord[] = [];
  pageSize: number = 5;
  pageIndex: number = 0;
  reachedEnd: boolean = false;
  customerSearch: boolean = true;
  customerSearch_customers: Customer[] = [];
  customerSearch_index = new BehaviorSubject<number>(0);
  customerSearch_progress = 0;
  customerSearch_currentCustomer!: Customer;
  recentPending: boolean = false;
  recentActive: boolean = false;


  displayedColumns: string[] = ['customerId', 'customerName', 'customerCountry', 'customerBillingAddress', 'customerShippingAddress', 'subscriptionStatus', "erpOrderNumber", 'customerPaymentEntered', 'subscriptionDescription', 'subscriptionContact', 'subscriptionSubmittedBy', 'subscriptionCreated'];
  //displayedExpandedColumns: string[] = [];//'subscriptionId', 'subscriptionCreated', 'subscriptionDescription', 'subscriptionStatus', 'subscriptionContact', 'hardwareShipped'];
  showLoading: boolean = false;
  currentUser: User | null = null;
  filterValue$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  filterValue: string = "";
  showSearchLoading = false;
  datePipe!: DatePipe;
  distributorView: boolean = true;
  private readonly _destroying$ = new Subject<void>();
  private readonly _tabswitch$ = new Subject<void>();

  constructor(private subscriptionService: SubscriptionService,
    private addressDisplayPipe: AddressDisplayPipe,
    private detailsDialog: MatDialog,
    private errorHandler: ErrorHandlerService,
    private loginFlowService: LoginFlowService,
    private contactService: ContactService,
    private customerService: CustomerService,
    private exportDialog: MatDialog,) { }

  ngOnInit(): void {
    this.datePipe = new DatePipe('en-US');
    this.checkLogin();
    this.getFilter();
    this.getCustomerRecords();
  }

  checkLogin(): void{
    this.loginFlowService.getUser().subscribe({
      next: user=>{
        this.currentUser = user;
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });    
  }

  exportAll(): void{
    const dialogRef = this.exportDialog.open(SubscriptionsDashboardExportComponent, {
      width: '90vw',
      closeOnNavigation: false,
      disableClose: true,
      data: {
      }
    });
  }

  resetRecords(){
    this._tabswitch$.next();
    this.records=[].slice();
    this.displayedRecords=[].slice();
    this.displayedPage=[].slice();
    this.showLoading=false;
    this.showSearchLoading = false;
    this.filterValue$.next("4ll_v4lue5");
  }

  getPendingSubs(): void{
    this.customerSearch=false;
    this.recentPending=true;
    this.recentActive=false;
    this.resetRecords();
    this.getCustomers(true);
    this.getFilter();
  }

  getActiveSubs(): void{
    this.customerSearch=false;
    this.recentPending=false;
    this.recentActive=true;
    this.resetRecords();
    this.getCustomers(false);
    this.getFilter();
  }

  searchByCustomer(){
    this.customerSearch_progress = 0;
    this.recentPending=false;
    this.recentActive=false;
    this.resetRecords();
    this.customerSearch=true;
    this.getFilter();
  }

  getCustomers(pending: boolean): void {
    this.showLoading = true;
    var $summariesCall = pending ? this.subscriptionService.getPendingSubscriptionSummaries(100) : this.subscriptionService.getSubscriptionSummaries(100);
    
      $summariesCall.pipe(takeUntil(this._tabswitch$)).subscribe({
      next: customers=> {
        customers.forEach(customer=>{
          customer.subscriptions.forEach(subscription=> {
            if (subscription.crmParentId != null && subscription.crmParentId != '') {
                this.distributorView = false;
              this.displayedColumns = ['customerId', 'customerName', 'customerCountry', 'customerBillingAddress', 'customerShippingAddress', 'subscriptionStatus', 'erpOrderNumber', 'customerPaymentEntered', 'parentId', 'parentName', 'subscriptionDescription', 'subscriptionContact', 'subscriptionSubmittedBy', 'subscriptionCreated'];
              }

              this.records.push({ customer: customer, subscription: subscription });
            });
          })
          this.displayedRecords = this._customerFilter(this.filterValue).slice();
          this.getCurrentPage();
          this.showLoading = false;
        },
        error: (err: HttpErrorResponse) => {
          this.errorHandler.handleError(err);
        }
      });

  }

  getFilter(): void {
    var filter$ = this.filterValue$.asObservable().pipe(takeUntil(this._tabswitch$));
    //non customer search tab behavior
    if(this.customerSearch){
      //customer search tab behavior
      filter$.pipe(
        distinctUntilChanged(),
        filter(value => value.length >= 4 && value!="4ll_v4lue5")).subscribe(()=> this.showSearchLoading = true);
        filter$.pipe(
          debounceTime(3000),
          distinctUntilChanged((previous: string, current:string)=> previous===current),
          filter(value => value.length >= 4 && value!="4ll_v4lue5"),
        ).subscribe(filterValue => {
          this.showSearchLoading = false;
          this.showLoading = true;
          this.searchCustomers(filterValue);
      });
    }else{
      filter$.subscribe(filterValue => {
          this.filterValue = filterValue;
          this.displayedRecords = this._customerFilter(filterValue).slice();
          this.getCurrentPage();
        });
    }
  }

  searchCustomers(filterValue: string){
    this.customerSearch_progress = 0;
    this.customerSearch_customers = [];
    this.customerSearch_index.next(0);
    this.records=[].slice();
    this.displayedRecords=[].slice();
    this.displayedPage=[].slice();
    var getCustomers$ = this.currentUser?.external ? this.customerService.getCustomers(filterValue) : this.customerService.getCustomersForUser(filterValue);

    getCustomers$.subscribe({
      next: customers=>{
        this.customerSearch_customers = customers;
        if(customers.length>0)
          this.customerSearch_index.next(0);
        else{
          this.showLoading = false;
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });
  }

  getCustomerRecords(){
    this.customerSearch_index.asObservable().pipe(takeUntil(this._destroying$),
      skipWhile(()=> !this.customerSearch || !this.showLoading || this.customerSearch_customers.length<1))
    .subscribe(index=>{
      this.customerSearch_currentCustomer = this.customerSearch_customers[index];
      this.customerSearch_progress = (index*100/this.customerSearch_customers.length)-((index*100/this.customerSearch_customers.length))%1;
      this.subscriptionService.getSubscriptionSummariesForCustomer(this.customerSearch_currentCustomer.id)
      .pipe(takeUntil(this._tabswitch$), skipWhile(()=> !this.customerSearch || !this.showLoading)).subscribe({
        next: customers=> {
          customers.forEach(customer=>{
            customer.subscriptions.forEach(subscription=> {
              if (subscription.crmParentId != null && subscription.crmParentId != '') {
                  this.distributorView = false;
                  this.displayedColumns = ['customerId', 'customerName', 'customerCountry', 'customerBillingAddress', 'customerShippingAddress', 'subscriptionStatus', 'erpOrderNumber', 'customerPaymentEntered', 'parentId', 'parentName', 'subscriptionDescription', 'subscriptionContact', 'subscriptionSubmittedBy', 'subscriptionCreated'];
                }

                this.records.push({ customer: customer, subscription: subscription });
              });
            })
            this.displayedRecords = this._customerFilter(this.filterValue).slice();
            this.getCurrentPage();
          
            if(index<this.customerSearch_customers.length-1){
              this.customerSearch_index.next(index+1);
            }else{
              this.showLoading = false;
              this.customerSearch_progress = 100;
            }
          },
          error: (err: HttpErrorResponse) => {
            this.errorHandler.handleError(err);
          }
        });
    });
  }

  cancelSearch(){
    this.showLoading = false;
    this.customerSearch_progress = 100;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    if(filterValue.trim().toLocaleLowerCase()=== ""){
      this.filterValue$.next("4ll_v4lue5");
    }else{
      this.filterValue$.next(filterValue.trim().toLowerCase());
    }
  }

  private _customerFilter(value: string): SubscriptionDashboardRecord[] {
    const filterValue = value.toLowerCase();
    if(filterValue === "4ll_v4lue5"){
      return this.records;
    }
    return this.records.filter(record => record?.subscription.crmCustomerId.toLowerCase().includes(filterValue)
      || record?.subscription?.customerName?.toLowerCase()?.includes(filterValue)
      || record?.customer?.country?.toLowerCase()?.includes(filterValue)
      || record?.customer?.shippingAddress?.line1?.toLowerCase()?.includes(filterValue)
      || record?.customer?.shippingAddress?.city?.toLowerCase()?.includes(filterValue)
      || record?.customer?.shippingAddress?.line2?.toLowerCase()?.includes(filterValue)
      || record?.customer?.shippingAddress?.postalCode?.toLowerCase()?.includes(filterValue)
      || record?.customer?.shippingAddress?.state?.toLowerCase()?.includes(filterValue)
      || record?.customer?.billingAddress?.line1?.toLowerCase()?.includes(filterValue)
      || record?.customer?.billingAddress?.city?.toLowerCase()?.includes(filterValue)
      || record?.customer?.billingAddress?.line2?.toLowerCase()?.includes(filterValue)
      || record?.customer?.billingAddress?.postalCode?.toLowerCase()?.includes(filterValue)
      || record?.customer?.billingAddress?.state?.toLowerCase()?.includes(filterValue)
      || record?.subscription?.erpOrderNumber?.toLowerCase()?.includes(filterValue)
      || record?.subscription?.status?.toLowerCase()?.includes(filterValue)
      || record?.subscription?.paymentType?.toLowerCase()?.includes(filterValue)
      || record?.subscription?.description?.toLowerCase()?.includes(filterValue)
      || record?.subscription?.contact?.toLowerCase()?.includes(filterValue)
      || record?.subscription?.submittedBy?.toLowerCase()?.includes(filterValue)
      || record?.subscription?.created?.toString()?.toLowerCase()?.includes(filterValue)
      || record?.subscription?.id?.toLowerCase()?.includes(filterValue)
    )
  }

  handlePageEvent(e: PageEvent) {
    if (this.pageSize != e.pageSize) {
      this.pageIndex = 0;
      this.pageSize = e.pageSize;
    } else {
      this.pageIndex = e.pageIndex;
    }
    this.getCurrentPage();

  }

  getCurrentPage(): void {
    var startIndex = this.pageIndex * this.pageSize;
    var endIndex = this.pageIndex * this.pageSize + this.pageSize;
    this.displayedPage = this.displayedRecords.slice(startIndex, endIndex > this.displayedRecords.length ? this.displayedRecords.length : endIndex);
  }

  DownloadTable() {
    var a = document.createElement("a");
    var contents = this.distributorView ?
      "customerId,customerName,customerCountry,customerBillingAddress,customerShippingAddress,subscriptionStatus,erpOrderNumber,paymentEntered,subscriptionDescription,subscriptionContact,subscriptionSubmittedBy,subscriptionCreated\n\"" :
      "customerId,customerName,customerCountry,customerBillingAddress,customerShippingAddress,subscriptionStatus,erpOrderNumber,paymentEntered,3rdPartyPaying,3rdPartyPayingName,subscriptionDescription,subscriptionContact,subscriptionSubmittedBy,subscriptionCreated\n\"";
    let areAllTheSameCustomer = true;
    let firstCustomerId = '';

    this.displayedRecords.forEach((record, index) => {
      if (index == 0)
        firstCustomerId = record.subscription.crmCustomerId
      else if (firstCustomerId != record.subscription.crmCustomerId)
        areAllTheSameCustomer = false

      contents = contents
        + (this.distributorView ? record.subscription.crmCustomerId : record.customer.crmId) + "\",\""
        + (this.distributorView ? record.subscription.customerName : record.customer.name) + "\",\""
        + record.customer.country + "\",\""
        + this.addressDisplayPipe.transform(record.customer.billingAddress) + "\",\""
        + this.addressDisplayPipe.transform(record.customer.shippingAddress) + "\",\""
        + record.subscription.status + "\",\""
        + record.subscription.erpOrderNumber  + "\",\""
        + record.subscription.paymentType + "\",\""
      + (!this.distributorView ? record.subscription.crmParentId + "\",\"" : "")
      + (!this.distributorView ? record.subscription.parentName + "\",\"" : "")
        + record.subscription.description + "\",\""
        + record.subscription.contact + "\",\""
        + record.subscription.submittedBy + "\",\""
        + this.datePipe.transform(record.subscription.created.toString(), 'dd/MM/YYYY') + "\"\n\"";
    });

    a.href = URL.createObjectURL(new Blob([contents], { type: 'text/csv' }));
    a.download = "SubscriptionDashboard" + (areAllTheSameCustomer ? this.getComplementaryDownloadNamingInformation() : "") + ".csv";

    a.click();
  }

  getComplementaryDownloadNamingInformation() {
    let extraNamingInformation = "";

    if (this.displayedRecords.length > 0) {
      extraNamingInformation = "ForCRM" + this.displayedRecords[0].customer.crmId
    }

    return extraNamingInformation;
  }

  openSubscriptionDetailsDialog(row: SubscriptionDashboardRecord): void{
    var custId = (row.subscription.paidBy == 5 ? row.subscription.crmParentId : row.customer.crmId);
    this.showLoading = true;

    this.contactService.getContacts(custId).subscribe({
      next: contacts=> {
        row.subscription.customerContact = contacts
        if (row.subscription.crmParentId != null)
        {
          this.contactService.getContacts(row.subscription.crmParentId).subscribe({
            next: contacts=> {
              row.subscription.payerContact = contacts;
              const dialogRef = this.detailsDialog.open(SubscriptionsDashboardDetailsPageComponent,
                {
                  width: '75%',
                  minHeight: 'calc(100vh - 90px)',
                  height : 'auto',          
                  data: row,
                  autoFocus: false
                 });
                this.showLoading = false;
            },
            error: (err: HttpErrorResponse) => {
              this.showLoading = false;
              this.errorHandler.handleError(err);
            }
          }); 
        }
        else
        {
          const dialogRef = this.detailsDialog.open(SubscriptionsDashboardDetailsPageComponent,
            {
              width: '75%',
              minHeight: 'calc(100vh - 90px)',
              height : 'auto',          
              data: row,
              autoFocus: false
             });
            this.showLoading = false;
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    }); 
  }

  ngOnDestroy(): void {
    this._tabswitch$.complete;
    this._destroying$.next();
    this._destroying$.complete();
  }
}
