<button mat-button color="basic" [matMenuTriggerFor]="langMenu">
    <mat-icon>language</mat-icon>
</button>
<mat-menu #langMenu="matMenu">
    <span mat-menu-item  *ngFor="let lang of availableLangs" (click)="change(lang.id)">
        <button mat-button [attr.data-cy]="lang.id" (click)="change(lang.id)" [color]="activeLang === lang.id ? 'primary' : 'basic'" class="lang-button">
            <span *transloco="let t">
                {{ t('language_selector-'+lang.label.toLowerCase()) }}
            </span>
        </button>
    </span>
    
</mat-menu>