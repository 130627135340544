<app-toolbar [pageName]="t('page_not_found-title')" *transloco="let t"></app-toolbar>
<div class="main-div">
    <mat-card class="home-card basic-card mat-elevation-z8">
        <p *transloco="let t"> 
            <mat-icon>
                error
            </mat-icon>
            {{t('page_not_found-message')}}
        </p>
    </mat-card>
</div>

