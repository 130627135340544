import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '../../common/interfaces/address.model';

@Pipe({
  name: 'addressDisplay'
})
export class AddressDisplayPipe implements PipeTransform {

  transform(address: Address | undefined): string {
    return address != null ? address.line1 + 
    " " + (address.line2 ?? "") +
    " " + address.city + 
    ", " + address.state + 
    " " + address.postalCode
    : "";
  }

}
