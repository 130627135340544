import { Component, OnInit, Input, HostListener } from '@angular/core';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { Language } from '../../../language/interfaces/language.model';
import { MsalService } from '@azure/msal-angular';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandlerService } from '../../services/error-handler.service';
import { UserAuthService } from '../../../user/services/user-auth.service';
import { LoginFlowService } from '../../../login-check/login-flow.service';
import { LoginState } from '../../../login-check/login-state.enum';
import { MatDialog } from '@angular/material/dialog';
import { LoginCheckComponent } from '../../../login-check/login-check.component';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { StripeAccount } from 'src/app/shared/stripe-accounts/interfaces/stripe-account.model';
import { StripeAccountService } from 'src/app/shared/stripe-accounts/services/stripe-account.service';
import { User } from 'src/app/shared/user/interfaces/user.model';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Input('pageName') pageName = '';
  @Input('hideButtons') hideButtons = false;
  public getScreenWidth: any;
  public getScreenHeight: any;
  public testBanner: boolean = environment.tstBanner;
  availableAccounts: StripeAccount[] = [];
  notAccounts: boolean = false;


  constructor(private stripeAccountService: StripeAccountService,
    private errorHandler: ErrorHandlerService,
    private loginFlowService: LoginFlowService
  ) {


  }

  ngOnInit(): void {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    this.getStripeAccounts();
  }

  getStripeAccounts(): void {
    this.loginFlowService.getStripeAccounts().subscribe({
      next: accounts => {
        if(accounts.length === 0){
          this.notAccounts = true;
        }
        this.availableAccounts = accounts;
        if(accounts.length === 1 && (accounts[0]?.id !== undefined && accounts[0]?.id !== null )){
          localStorage.setItem("accountSelected", accounts[0]?.id) 
        }
        else if(accounts.length > 1 && (accounts[0]?.id !== undefined && accounts[0]?.id !== null ) &&
         (localStorage.getItem("accountSelected") === undefined || localStorage.getItem("accountSelected") === "" || localStorage.getItem("accountSelected") === null)){
          localStorage.setItem("accountSelected", accounts[0]?.id) 
        }
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }

    });

    
    // Get stripe accounts if it's not getting any account through login-flow due that the user it's external
    if(this.notAccounts){
      this.stripeAccountService.get().subscribe({
        next: accounts => {
          accounts.forEach(account=>{
            this.availableAccounts.push({label: account.label, id: account.id});
  
          })
          if(accounts.length === 1 && (accounts[0]?.id !== undefined && accounts[0]?.id !== null )){
            localStorage.setItem("accountSelected", accounts[0]?.id) 
          }
          else if(accounts.length > 1 && (accounts[0]?.id !== undefined && accounts[0]?.id !== null ) &&
           (localStorage.getItem("accountSelected") === undefined || localStorage.getItem("accountSelected") === "" || localStorage.getItem("accountSelected") === null)){
            localStorage.setItem("accountSelected", accounts[0]?.id) 
          }
        },
        error: (err: HttpErrorResponse) => {
          this.errorHandler.handleError(err);
        }
      });
      this.notAccounts = false;
    }
  }

  get activeAccount() {
    return localStorage.getItem("accountSelected") ?? ""
  }

  change(activeAccount: string) {
    const currentAccount = localStorage.getItem("accountSelected");
    if(currentAccount !== activeAccount) {
      localStorage.setItem("accountSelected", activeAccount)
      window.location.reload();
    }
  }


  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
  }


}
