<div class="main-div">
    <form>
        <div class="information-section">
            <div>
                <label *transloco="let t" class="section-header">{{t('customer_dashboard_details-customer_information_header')}}</label>
            </div>
            <div class="information-details">
                <div class="information-segment">
                    <label *transloco="let t">{{t('customer_dashboard_details-customer_id')}}:</label>
                    <span>#{{data.paymentCustomer?.crmId ?? ""}}</span>
                </div>
                <div class="information-segment">
                    <label *transloco="let t">{{t('customer_dashboard_details-company_name')}}: </label>
                    <span>{{data.customer?.name ?? ""}}</span>
                </div>
                <br/>
                <div class="information-segment">
                    <label *transloco="let t">{{t('customer_dashboard_details-address')}}: </label>
                    <span>{{data.customer?.address | addressDisplay}}</span>
                </div>
                <br/>
            </div>
        </div>
        <div class="information-section">
            <div>
                <label *transloco="let t" class="section-header">{{t('customer_dashboard_details-active_subscriptions_header')}}</label>
            </div>
            <div class="table-div">
                <table mat-table [dataSource]="data.subscriptions" class="mat-elevation-z8 recordsTable">           
                    <ng-containter matColumnDef="customerId">
                        <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('customer_dashboard_details-customer_id') }}</span></th>
                        <td mat-cell *matCellDef="let record" class="recordsCell">
                            {{ record.customerIdCrm }}
                        </td>
                    </ng-containter>

                    <ng-containter matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('customer_dashboard_details-company_name') }}</span></th>
                        <td mat-cell *matCellDef="let record" class="recordsCell">
                            {{ record.customerName }}
                        </td>
                    </ng-containter>

                    <ng-containter matColumnDef="address">
                        <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('customer_dashboard_details-address') }}</span></th>
                        <td mat-cell *matCellDef="let record" class="recordsCell">
                            {{ record.customerAddress | addressDisplay }}
                        </td>
                    </ng-containter>

                    <ng-containter matColumnDef="subscriptionDescription">
                        <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('customer_dashboard_details-subscription') }}</span></th>
                        <td mat-cell *matCellDef="let record" class="recordsCell">
                            {{ record.description }}
                        </td>
                    </ng-containter>

                    <ng-containter matColumnDef="paymentDate">
                        <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('customer_dashboard_details-paydate') }}</span></th>
                        <td mat-cell *matCellDef="let record" class="recordsCell">
                            {{record.nextBill | date:'fullDate'}} 
                        </td>
                    </ng-containter>

                    <ng-containter matColumnDef="contact">
                        <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('customer_dashboard_details-contact') }}</span></th>
                        <td mat-cell *matCellDef="let record" class="recordsCell">
                            {{record.contact}} 
                        </td>
                    </ng-containter>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns" class="recordsHeader"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </div>
        </div>
        <div class="information-section" *ngIf="billingPortalUrl!=''">
            <button mat-raised-button color="primary" *transloco="let t" (click)="manageAccountClick()">{{ t('customer_dashboard_details-manage_account') }}</button>
        </div>
    </form>
</div>