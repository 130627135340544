<app-toolbar [pageName]="t('incomplete_subscription_page-title')" *transloco="let t"></app-toolbar>
<div class="main-div">
    <div class="table-div">
        <table mat-table [dataSource]="incompleteSubscriptions" class="mat-elevation-z8 recordsTable" matSort (matSortChange)="sortSubscriptions($event)">
            <ng-containter matColumnDef="customerId">
                <th mat-header-cell *matHeaderCellDef><span mat-sort-header="customerId" *transloco="let t">{{ t('incomplete_subscriptions_page-customer_id_num') }}</span></th>
                <td mat-cell *matCellDef="let record" class="recordsCell" (click)="openActivateSubscriptionDialog(record)">{{ record.subscription.customerIdCRM}}</td>
            </ng-containter>
    
            <ng-containter matColumnDef="customerName">
                <th mat-header-cell *matHeaderCellDef><span mat-sort-header="customerName" *transloco="let t">{{ t('incomplete_subscriptions_page-company_name') }}</span></th>
                <td mat-cell *matCellDef="let record" class="recordsCell" (click)="openActivateSubscriptionDialog(record)">
                    {{ record.customer.name }}
                </td>
            </ng-containter>
    
            <ng-containter matColumnDef="customerAddress">
                <th mat-header-cell *matHeaderCellDef><span mat-sort-header="customerAddress" *transloco="let t">{{ t('incomplete_subscriptions_page-address') }}</span></th>
                <td mat-cell *matCellDef="let record" class="recordsCell" (click)="openActivateSubscriptionDialog(record)">
                    {{ record.customer.address | addressDisplay}}
                </td>
            </ng-containter>
    
            <ng-containter matColumnDef="subscriptionName">
                <th mat-header-cell *matHeaderCellDef><span mat-sort-header="subscriptionName" *transloco="let t">{{ t('incomplete_subscriptions_page-subscription') }}</span></th>
                <td mat-cell *matCellDef="let record" class="recordsCell" (click)="openActivateSubscriptionDialog(record)">{{ record.subscription.product.name }}</td>
            </ng-containter>
    
            <ng-containter matColumnDef="dateSubmitted">
                <th mat-header-cell *matHeaderCellDef><span mat-sort-header="dateSubmitted" *transloco="let t">{{ t('incomplete_subscriptions_page-date_submitted') }}</span></th>
                <td mat-cell *matCellDef="let record" class="recordsCell" (click)="openActivateSubscriptionDialog(record)">{{ record.subscription.created | date:'short' }}</td>
            </ng-containter>
    
            <ng-containter matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef><span mat-sort-header="status" *transloco="let t">{{ t('incomplete_subscriptions_page-status') }}</span></th>
                <td mat-cell *matCellDef="let record" class="recordsCell" (click)="openActivateSubscriptionDialog(record)">{{ record.subscription.status }}</td>
            </ng-containter>

            <ng-containter matColumnDef="newTab">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let record" class="recordsCell newTab" (click)="openActivateSubscriptionDialogNewTab(record)"><mat-icon>open_in_new</mat-icon></td>
            </ng-containter>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns" class="recordsHeader"></tr>
            
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="recordRow"></tr>
        </table>
        <div *ngIf="showLoading">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
    </div>
    
</div>

