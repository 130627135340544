import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { catchError, Observable, retry, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../interfaces/user.model';
import { BehaviorSubject, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserAuthService {
  private _baseUrl = environment.apiBaseURL + "LoginUser";
  private user: any;
  constructor(private http: HttpClient) { }

  get(): Observable<User> {
    return this.user ? of(this.user) : 
    this.http.get<User>(this._baseUrl+"/Get", {withCredentials: true}).pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
  }

  getFresh(): Observable<User> { //don't pass back credentials cookie, force refeshing of user authorization
    var user = this.http.get<User>(this._baseUrl+"/Get").pipe(
      retry(2),
      catchError((error: HttpErrorResponse) => {
        console.error(error);
        return throwError(() => error);
      })
    );
    return user;
  }
}
