<app-toolbar [pageName]="t('subscription_dashboard_page-title')" *transloco="let t"></app-toolbar>
<!-- <button mat-raised-button color="accent" routerLink="/subscription/dashboard" [disabled]="true" type="button" class="navButton">
    <span  *transloco="let t">{{ t('subscription_dashboard_page-title') }}</span>
    <mat-icon class="toolbar-icon">dashboard</mat-icon>
</button> -->
<!-- <button mat-raised-button color="accent" routerLink="/crm/dashboard"  type="button" class="navButton">
    <span  *transloco="let t">{{ t('customer_dashboard_page-title') }}</span>
    <mat-icon class="toolbar-icon">dashboard</mat-icon>
</button> -->
<div class="main">
    <mat-card class="filter-card">
        <mat-card-content>
            <mat-form-field appearance="fill" class="searchbar" *ngIf="recentActive">
                <mat-label><span *transloco="let t">{{ t('subscription_dashboard_page-active_search') }}</span></mat-label>
                <mat-label *ngIf="customerSearch"><span *transloco="let t">{{ t('subscription_dashboard_page-customer_search') }}</span></mat-label>
                <input matInput type="text" (keyup)="applyFilter($event)" [disabled]="showLoading">
                <mat-icon matSuffix>
                    search
                </mat-icon>
                <div *ngIf="showSearchLoading">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
            </mat-form-field>
            <mat-form-field appearance="fill" class="searchbar" *ngIf="recentPending">
                <mat-label><span *transloco="let t">{{ t('subscription_dashboard_page-active_search') }}</span></mat-label>
                <mat-label *ngIf="customerSearch"><span *transloco="let t">{{ t('subscription_dashboard_page-customer_search') }}</span></mat-label>
                <input matInput type="text" (keyup)="applyFilter($event)" [disabled]="showLoading">
                <mat-icon matSuffix>
                    search
                </mat-icon>
                <div *ngIf="showSearchLoading">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
            </mat-form-field>
            <mat-form-field appearance="fill" class="searchbar" *ngIf="customerSearch">
                <mat-label><span *transloco="let t">{{ t('subscription_dashboard_page-customer_search') }}</span></mat-label>
                <input matInput type="text" (keyup)="applyFilter($event)" [disabled]="showLoading" >
                <mat-icon matSuffix>
                    search
                </mat-icon>
                <div *ngIf="showSearchLoading">
                    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                </div>
            </mat-form-field>
        </mat-card-content>
        <mat-card-actions align="start">
            <button mat-raised-button color="accent" type="button" class="navButton" (click)="DownloadTable()">
                <span  *transloco="let t">{{ t('subscription_dashboard_page-download_button') }}</span>
            </button>
            <button mat-raised-button color="accent" type="button" class="navButton" (click)="exportAll()">
                <span  *transloco="let t">{{ t('subscription_dashboard_page-export_all_button') }}</span>
            </button>
        </mat-card-actions>
    </mat-card>
    <div>
        <button mat-raised-button color="accent"  type="button" class="navButton" [disabled]="customerSearch" (click)="searchByCustomer()">
            <span  *transloco="let t">{{ t('subscription_dashboard_page-search_customers') }}</span>
            <mat-icon class="toolbar-icon">search</mat-icon>
        </button>
        <button mat-raised-button color="accent" type="button" class="navButton" [disabled]="recentPending" (click)="getPendingSubs()">
            <span  *transloco="let t">{{ t('subscription_dashboard_page-pending_subs') }}</span>
        </button>
        <button mat-raised-button color="accent" type="button" class="navButton" [disabled]="recentActive" (click)="getActiveSubs()">
            <span  *transloco="let t">{{ t('subscription_dashboard_page-active_subs') }}</span>
        </button>
    </div>
    <div *ngIf="customerSearch_progress>0&&customerSearch_progress<100&&customerSearch">
        <span *ngIf="customerSearch_progress<100"><span  *transloco="let t">{{ t('subscription_dashboard_page-customer_search_progress') }}</span>{{customerSearch_currentCustomer.id}}</span>
        <mat-progress-bar mode="determinate" [value]="customerSearch_progress"></mat-progress-bar>
        <button mat-raised-button color="accent" type="button" class="navButton" (click)="cancelSearch()" *ngIf="customerSearch_progress>0&&customerSearch_progress<100">
            <span  *transloco="let t">{{ t('subscription_dashboard_page-cancel_customer_search') }}</span>
        </button>
    </div>
    <div class="mat-elevation-z8 table-div">
        <div class="table-div-inner">
        
            <table mat-table [dataSource]="displayedPage" class="recordsTable" >
                <ng-containter matColumnDef="customerId">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('subscription_dashboard_page-customer_id_num') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.subscription.crmCustomerId ?? record.customer.crmId ?? "" }}
                    </td>
                </ng-containter>
        
                <ng-containter matColumnDef="customerName">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('subscription_dashboard_page-company_name') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.subscription.customerName ?? record.customer.name ?? "" }}
                    </td>
                </ng-containter>
    
                <ng-containter matColumnDef="customerCountry">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('subscription_dashboard_page-country') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.customer.country ?? "" }}
                    </td>
                </ng-containter>
        
                <ng-containter matColumnDef="customerBillingAddress">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('subscription_dashboard_page-billing_address') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.customer.billingAddress | addressDisplay }}
                    </td>
                </ng-containter>
    
                <ng-containter matColumnDef="customerShippingAddress">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('subscription_dashboard_page-shipping_address') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.customer.shippingAddress | addressDisplay }}
                    </td>
                </ng-containter>

                <ng-containter matColumnDef="subscriptionStatus">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('subscription_dashboard_page-subscription_status') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.subscription.status | uppercase }} 
                    </td>
                </ng-containter>

                <ng-containter matColumnDef="erpOrderNumber">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('subscription_dashboard_page-subscription_erpOrderNumber') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.subscription.erpOrderNumber }} 
                    </td>
                </ng-containter>
    
                <ng-containter matColumnDef="customerPaymentEntered">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t" class="mat-header-cell-small">{{ t('subscription_dashboard_page-payment_entered') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.subscription.paymentType | uppercase }}
                    </td>
                </ng-containter>

                <ng-containter matColumnDef="parentId">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('subscription_dashboard_page-paid_by_id') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.subscription.crmParentId ?? "" }}
                    </td>
                </ng-containter>

                <ng-containter matColumnDef="parentName">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('subscription_dashboard_page-paid_by_customer') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.subscription.parentName ?? "" }}
                    </td>
                </ng-containter>
    
                <ng-containter matColumnDef="subscriptionId">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('subscription_dashboard_page-subscription_id') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.subscription.id ?? "" }}
                    </td>
                </ng-containter>
    
                <ng-containter matColumnDef="subscriptionDescription">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('subscription_dashboard_page-subscription_description') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.subscription.description }} 
                    </td>
                </ng-containter>
    
                <ng-containter matColumnDef="subscriptionContact">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('subscription_dashboard_page-subscription_contact') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.subscription.contact }} 
                    </td>
                </ng-containter>

                <ng-containter matColumnDef="subscriptionSubmittedBy">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('subscription_dashboard_page-subscription_submitted_by') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ record.subscription.submittedBy }} 
                    </td>
                </ng-containter>
    
                <ng-containter matColumnDef="hardwareShipped">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t" class="mat-header-cell-small">{{ t('subscription_dashboard_page-hardware_shipped') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCellCenter">
                        <mat-checkbox [checked]="record.subscription.hardwareShipped" [disabled]="true"></mat-checkbox>
                    </td>
                </ng-containter>  

                <ng-containter matColumnDef="subscriptionCreated">
                    <th mat-header-cell *matHeaderCellDef><span *transloco="let t">{{ t('subscription_dashboard_page-subscription_created') }}</span></th>
                    <td mat-cell *matCellDef="let record" class="recordsCell">
                        {{ (record.subscription.paidBy === 1 ? record.subscription.paidForCreated:record.subscription.created)  | date:'fullDate' }} 
                    </td>
                </ng-containter>               
    
                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="recordsHeader"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'unchargedSubscription' : row.subscription.unchargedSubscription, 'recordRow' : !row.subscription.unchargedSubscription }" (click)="openSubscriptionDetailsDialog(row)"></tr>
            </table>
            <div *ngIf="showLoading">
                <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            
        </div>
        <mat-paginator #paginator [length]="displayedColumns.length" 
                (page)="handlePageEvent($event)"
                [length]="displayedRecords.length"
                [pageSize]="pageSize"
                [pageIndex]="pageIndex"
                [disabled]="showLoading"
                [showFirstLastButtons]="false"
                [pageSizeOptions]="[3, 5, 10, 15, 30]"
                [hidePageSize]="false"
                ></mat-paginator>
    </div>
</div>

