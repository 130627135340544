<mat-toolbar class="toolbar">
    <mat-toolbar-row>
        <span class="toolbar-spacer center-elements">
            <img src="assets/images/ppg_lg_rgb_pos.png" width="60" height="50" >
            <div *ngIf="!hideButtons"><app-stripe-account-selector class="centered-element" [availableAccounts]="availableAccounts"
                (selectedStripeAccount)="change($event)" [activeAccount]="activeAccount" ></app-stripe-account-selector></div>
        </span>


        <span class="toolbar-spacer-center">
            <h1 *transloco="let t" color="basic">{{pageName}}</h1>
        </span>
        <span class="toolbar-spacer-right">
            <app-toolbar-menu *ngIf="getScreenWidth > 700 && !hideButtons"></app-toolbar-menu>
        </span>
    </mat-toolbar-row>
    <mat-toolbar-row *ngIf="getScreenWidth <= 700  && !hideButtons">
        <span class="toolbar-spacer"></span>
        <span class="toolbar-spacer">
            <app-toolbar-menu></app-toolbar-menu>
        </span>
        <span class="toolbar-spacer"></span>
    </mat-toolbar-row>
    <mat-toolbar-row *ngIf="testBanner" class="testBanner">
        <span *transloco="let t" color="basic" class="toolbar-spacer-center">{{'TEST'}}</span>
    </mat-toolbar-row>
</mat-toolbar>