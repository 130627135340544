import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Contact } from 'src/app/shared/customer/interfaces/contact.model';
import { ContactBuildPipe } from 'src/app/shared/customer/pipes/contact-build.pipe';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import { ContactSelector } from '../interfaces/contact-selector-data.model';

@Component({
  selector: 'app-contact-selector',
  templateUrl: './contact-selector.component.html',
  styleUrls: ['./contact-selector.component.scss']
})
export class ContactSelectorComponent implements OnInit {
  contactTypeControl = new  FormControl<string | Contact>('', [Validators.required]);
  selectCustomerContact= new FormControl<string | Contact>('', [Validators.required]);
    
  newContactFirstName= new FormControl<string>('', [Validators.required]);
  newContactLastName= new FormControl<string>('', [Validators.required]);
  newContactEmail= new FormControl<string>('', [Validators.required,Validators.email]);
  newContactFormGroup = new FormGroup({
    newContactFirstName: this.newContactFirstName,
    newContactLastName: this.newContactLastName,
    newContactEmail: this.newContactEmail
  })
  contactFormGroup = new FormGroup({
    contactTypeControl: this.contactTypeControl,
    selectCustomerContact: this.selectCustomerContact,
    newContactFormGroup: this.newContactFormGroup
  })

  validContact: boolean = false;

  matcher = new ErrorStateMatcher();
  validInput: boolean = false;

  selectedContact: Contact | string | null = '';

  constructor(public dialogRef: MatDialogRef<ContactSelectorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ContactSelector, 
    private contactBuildPipe: ContactBuildPipe,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandlerService) { }

  ngOnInit(): void { 
    this.checkIsValidContact();   

  }
  checkIsValidContact(): void{
    this.contactFormGroup.valueChanges.subscribe({
      next: value=> this.validContact = this._validateContact(),
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    });
  }

  _validateContact(): boolean{
    switch(this.contactTypeControl.value){
      case typeof "string" && "currentUserContact":{
        this.selectedContact = this.data.currentUserContact;
        return true;
      }
      case "customerContact": {
        this.selectedContact = this.selectCustomerContact.value;
        return this.selectCustomerContact.valid;
      }
      case "newContact": {
        const contactValues = this.newContactFormGroup.value;
        if (contactValues.newContactFirstName != null && contactValues.newContactLastName != null && contactValues.newContactEmail != null){
          this.selectedContact = this.contactBuildPipe.transform(contactValues.newContactFirstName, contactValues.newContactLastName, contactValues.newContactEmail)
        }
        
      //todo: if contact is valid, create contact in vantage point and validate contact is created

        return this.newContactFormGroup.valid;
      }
      default:{
        return false;
      }
    }
  }

  cancelClick(): void{
    this.dialogRef.close();
  }

}
