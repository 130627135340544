<app-toolbar [pageName]="t('home_page-title')" [hideButtons]="false" *transloco="let t"></app-toolbar>

<div>
    <div class="main-div" *ngIf="loadingWheel">
        <div *ngIf="!errorState; else errorDiv">
            <div class="home-card" >
                <mat-spinner color="accent"></mat-spinner>
            </div>
        </div>
        <ng-template #errorDiv>
            <div class="home-card" >
                <span *transloco="let t">{{ t('checkout_page-error') }}</span>
            </div>
        </ng-template>
    </div>
</div>