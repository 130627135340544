import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, of, skipWhile, take } from 'rxjs';
import { Customer } from 'src/app/shared/payment/interfaces/customer.model';
import { CustomerService } from 'src/app/shared/payment/services/customer.service';
import { ErrorHandlerService } from 'src/app/shared/navigation/services/error-handler.service';
import { User } from 'src/app/shared/user/interfaces/user.model';
import { UserAuthService } from 'src/app/shared/user/services/user-auth.service';
import { LoginState } from './login-state.enum';
import { Subscription } from '../subscription/interfaces/subscription.model';
import { SubscriptionService } from '../subscription/services/subscription.service';
import { SubscriptionCheckout } from '../payment/interfaces/subscription-checkout.model';
import { StripeAccountService } from '../stripe-accounts/services/stripe-account.service';
import { StripeAccount } from '../stripe-accounts/interfaces/stripe-account.model';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { LoginCheckComponent } from './login-check.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class LoginFlowService {
  currentUser: BehaviorSubject<User> = new BehaviorSubject<any>({});
  checkCurrentUser = true;
  customerAssignments: BehaviorSubject<Customer[]> = new BehaviorSubject<Customer[]>([]);
  checkCustomers: boolean = true;
  checkPendingSubscriptions: boolean = true;
  pendingSubscriptionCheckouts: BehaviorSubject<SubscriptionCheckout[]> = new BehaviorSubject<SubscriptionCheckout[]>([]);
  checkStripeAccounts: boolean = true;
  stripeAccounts: BehaviorSubject<StripeAccount[]> = new BehaviorSubject<StripeAccount[]>([]);
  state: BehaviorSubject<LoginState> = new BehaviorSubject<LoginState>(LoginState.Initial);
  waitingUserInput: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private calculatingState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  showSetupComplete = false;


  constructor(private customerService: CustomerService,
    private subscriptionService: SubscriptionService,
    private userAuthService: UserAuthService,
    private stripeAccountService: StripeAccountService,
    private errorHandler: ErrorHandlerService,
    private activatedRoute: ActivatedRoute, private loginDialog: MatDialog) {

  }

  public getState(): Observable<LoginState> {
    return this.state.asObservable();
  }

  public isUserInputRequired(): Observable<boolean> {
      return this.waitingUserInput.asObservable();
  }

  public isCalculatingState(): Observable<boolean>{
    return this.calculatingState.asObservable();
  }

  public setupComplete() {
    this.calcNextState(8);
  }

  public getUser(): Observable<User>{
    if(this.checkCurrentUser){
      this.populateUser();
    }
    return this.currentUser?.asObservable().pipe(skipWhile(()=>this.checkCurrentUser));

  }

  public calculateState() {
    this.calcNextState(0);
  }

  public refreshCustomers() {
    this.checkCustomers = true;
    this.populateCustomers();
  }

  public refreshUser() {
    this.checkCurrentUser = true;
    this.calcNextState(0);
  }

  public refreshSubscriptionCheckouts() {
    this.checkPendingSubscriptions = true;
    this.calcNextState(0);
  }

  public setCustomer(customer: Customer) {
    var customers = this.customerAssignments.getValue();
    var customerIndex = customers.findIndex(cust => cust.id == customer.id);
    if (customerIndex = -1) {
      customers.push(customer);
    } else {
      customers[customerIndex] = customer;
    }
    this.customerAssignments.next(customers);
  }

  public getCustomerAssignments(): Observable<Customer[]> {
    return this.customerAssignments.asObservable();
  }

  public getPendingCheckouts(): Observable<SubscriptionCheckout[]> {
    return this.pendingSubscriptionCheckouts.asObservable();
  }

  public getStripeAccounts(): Observable<StripeAccount[]> {
    return this.stripeAccounts.asObservable();
  }

  private calcNextState(nextState: number): void { //If a state's prereqs are filled, recursive call to move to next state 
    if (nextState > this.state.getValue()) { //state has been advanced but not updated yet
      this.state.next(nextState);
    }

    var currentState = nextState;
    nextState +=1;

    switch(currentState){
      case LoginState.Initial:{
        if(this.checkCurrentUser){
          this.waitingUserInput.next(false);
          this.calculatingState.next(true);
          this.populateUser();
          this.currentUser.pipe(skipWhile(()=>this.checkCurrentUser), take(1)).subscribe(user=>{
            this.calcNextState(user.external ? nextState : LoginState.Complete); //if the user is not external, jump to complete
          });
        }else{
          this.calcNextState(this.currentUser.getValue().external ? nextState : LoginState.Complete); //if the user is not external, jump to complete
        }
        
        break;
      }
      case LoginState.AssociateCustomer: {
        this.waitingUserInput.next(false);
        this.calculatingState.next(true);
        this.currentUser.pipe(take(1)).subscribe(user=>{
          if (!user.external || user.authorizations.length != 0) {
            this.calcNextState(nextState);
          }else{
            this.waitingUserInput.next(true);
            this.calculatingState.next(false);
          }
        });
        break;
      }
      case LoginState.GetStripeAccounts: {
        this.GetStripeAccounts();
        this.stripeAccounts.asObservable().pipe(skipWhile(()=> this.checkStripeAccounts), take(1))
        .subscribe(()=>this.calcNextState(nextState));
        break;
      }
      case LoginState.GetCustomers: {
        this.populateCustomers();
        this.customerAssignments.asObservable().pipe(skipWhile(()=> this.checkCustomers), take(1))
        .subscribe(()=>this.calcNextState(nextState));
        break;
      }
      case LoginState.ValidateTax: {
        this.waitingUserInput.next(false);
        this.calculatingState.next(true);
        this.customerAssignments.asObservable().pipe(skipWhile(()=> this.checkStripeAccounts || this.checkCustomers ), take(1))
        .subscribe(customers=>{
          // remove null customers
          customers = customers.filter(customer => customer != null);
          if (!customers.some(customer => customer?.taxIdStatus != 'initialized')) {
            this.calcNextState(nextState);
          }else{
            this.waitingUserInput.next(true);
            this.calculatingState.next(false);
          }
        })
        break;
      }
      case LoginState.GetPaymentCheckouts: {
        this.populatePendingSubscriptions();
        this.calcNextState(nextState);
        break;
      }
      case LoginState.ValidatePayment: {
        this.waitingUserInput.next(false);
        this.calculatingState.next(true);
        this.pendingSubscriptionCheckouts.asObservable().pipe(skipWhile(()=>this.checkPendingSubscriptions), take(1))
        .subscribe(pendingSubscriptions=> {
          if (pendingSubscriptions.filter(subCheckout => subCheckout.acceptInStripe).length == 0) {
            this.calcNextState(nextState);
          }else{
            this.waitingUserInput.next(true);
            this.calculatingState.next(false);
          }
        });
        break;
      }
      case LoginState.ValidateAcceptTermsAndConditions: {
        this.waitingUserInput.next(false);
        this.calculatingState.next(true);
        this.pendingSubscriptionCheckouts.asObservable().pipe(skipWhile(()=>this.checkPendingSubscriptions), take(1))
        .subscribe(pendingSubscriptions=> {
          if (pendingSubscriptions.filter(subCheckout => subCheckout.termsAndConditions != null).length == 0) {
            this.calcNextState(nextState);
          }else{
            this.waitingUserInput.next(true);
            this.calculatingState.next(false);
          }
        });
        
        break;
      }
      case LoginState.Complete: {
        this.waitingUserInput.next(false);
        this.calculatingState.next(false);
        this.activatedRoute.queryParams.subscribe(params => {
          if (params["showsetupcomplete"] != null)
          {
            this.showSetupComplete = params["showsetupcomplete"] != null ? Boolean(JSON.parse(params["showsetupcomplete"])) : false;
            if (this.showSetupComplete)
            {
              this.loginDialog.open(LoginCheckComponent,{
                width: '90vw',
                closeOnNavigation: false,
                disableClose: true
              });
            }
          }          
        });
        break;
      }
    }
  }

  private populateUser(): void {
    this.userAuthService.getFresh().subscribe(({
      next: user=> {
        this.checkCurrentUser = false;

        this.currentUser.next(user);
      },
      error: (err: HttpErrorResponse) => {
        this.errorHandler.handleError(err);
      }
    }))
  }

  private populateCustomers() {
    if (this.checkCustomers) {
      
      this.customerService.getCustomersByCrmIds(this.currentUser.getValue().authorizations.map(authorization => authorization.customerId))
        .subscribe({
          next: customers => {
            this.checkCustomers = false;
            this.customerAssignments.next(customers);
          },
          error: (err: HttpErrorResponse) => {
            this.errorHandler.handleError(err);
          }
        })
    }

  }

  private populatePendingSubscriptions() {
    if (this.checkPendingSubscriptions) {
      this.subscriptionService.getPendingSubscriptionCheckouts()
        .subscribe({
          next: subscriptions => {
            this.checkPendingSubscriptions = false;
            this.pendingSubscriptionCheckouts.next(subscriptions);
          },
          error: (err: HttpErrorResponse) => {
            this.errorHandler.handleError(err);
          }
        })
    }
  }

  private GetStripeAccounts() {
    if(this.checkStripeAccounts){
      
      this.stripeAccountService.get()
      .subscribe({
        next: accounts => {
          this.checkStripeAccounts = false;
          let userAccounts: StripeAccount[] = [];
          accounts.forEach(account => {
            userAccounts.push({ label: account.label, id: account.id });
  
          })
          this.stripeAccounts.next(userAccounts);
  
          if (accounts.length === 1 && (accounts[0]?.id !== undefined && accounts[0]?.id !== null)) {
            localStorage.setItem("accountSelected", accounts[0]?.id)
          }
          else if (accounts.length > 1 && (accounts[0]?.id !== undefined && accounts[0]?.id !== null) &&
            (localStorage.getItem("accountSelected") === undefined || localStorage.getItem("accountSelected") === "" || localStorage.getItem("accountSelected") === null)) {
            localStorage.setItem("accountSelected", accounts[0]?.id)
          }
        },
        error: (err: HttpErrorResponse) => {
          this.errorHandler.handleError(err);
        }
      });
    }

  }
}
